import { endpoints } from "./declarations";

export default endpoints({

  "InfraV2/blue-green": {
    url: import.meta.env.VITE_InfraV2_BLUE_GREEN as string,
    roles: ["api-access"]
  },
  "InfraV2/dev": {
    url: import.meta.env.VITE_InfraV2_DEV as string,
    roles: ["api-access"]
  },
  "InfraV2/test": {
    url: import.meta.env.VITE_InfraV2_TEST as string,
    roles: ["api-access"]
  },
  "InfraV2/stage": {
    url: import.meta.env.VITE_InfraV2_STAGE as string,
    roles: ["api-access"]
  },

  "TenantAPI/dev": {
    url: "https://api.dev.iotcomms.io/tenant",
    roles: ["api-access", "send-email-announcements"]
  },
  "TenantAPI/test": {
    url: "https://api.test.iotcomms.io/tenant",
    roles: ["api-access", "send-email-announcements"]
  },
  "TenantAPI/stage": {
    url: "https://api.stage.iotcomms.io/tenant",
    roles: ["api-access", "send-email-announcements"]
  },
  "TenantAPI/prod-eu-north-1": {
    url: "https://api.prod-eu-north-1.iotcomms.io/tenant",
    roles: ["api-access", "api-read-only", "send-email-announcements"],
  },
  "TenantAPI/prod-us-east-1": {
    url: "https://api.prod-us-east-1.iotcomms.io/tenant",
    roles: ["api-access", "api-read-only", "send-email-announcements"],
  }

});


