
import React, { useState, useMemo } from "react";
import styled from "styled-components";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";


import { SimpleSelect } from "./Inputs";



import {
  EnhancedTableToolbar,
  EnhancedTableHead,
  Order,
  HeadCell,
  useStyles,
  getComparator,
  stableSort,
  LoadingContainer,
  StyledCheckbox,
  StyledRow
} from "./TableComponents";
import { handleTenantTableCellClick, parseTenantRows, TenantData, getLocaleTime } from "./tableUtils";


import useData, { UseDataResponse } from "../customHooks/useData";


const TenantTableCell = styled(TableCell)`
  max-width: 200px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
`;


interface TableProps {
  path?: string;
  searchString: string;
  setSelected: (value: TenantData[]) => void;
  selected: TenantData[],
  tenants: UseDataResponse,
  reload: boolean,
  handleDeleteTenant: () => void;
  handleRecoverTenant: () => void;
  deleteRequests: any[];
  action?: string;
}



function EnhancedTable({ searchString, setSelected, selected, reload, tenants, handleDeleteTenant, handleRecoverTenant, deleteRequests, action }: TableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof TenantData>("created");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useData({});


  const rows = parseTenantRows({ tenants, searchString, selected, deleteRequests, returnMarked: action === "recover" });



  const headCells: HeadCell[] = useMemo(() => {

    const defaultHead = [
      { id: "domain", numeric: false, disablePadding: true, label: "Domain" },
      { id: "system", numeric: true, disablePadding: false, label: "System" },
    ];
    if (action === "recover") {
      defaultHead.push({ id: "ttl", numeric: false, disablePadding: false, label: "Time to Live" });
    }
    return defaultHead;
  }, [action]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TenantData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(rows);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string) => {
    const newSelected = handleTenantTableCellClick({ id, rows, selected });

    if (newSelected) {
      return setSelected(newSelected);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.map((n: TenantData) => n.id).indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  type Page = {
    value: string;
    label: string;
  };

  const pages = (): Array<Page> => {
    const numOfPages = Math.ceil(rows.length / rowsPerPage);
    const p = [];
    for (let i = 1; i <= numOfPages; i++) {
      p.push({ value: i.toString(), label: i.toString() });
    }
    return p;
  };


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          handleDelete={handleDeleteTenant}
          handleRecover={handleRecoverTenant}
          numSelected={selected.length}
          tableTitle={action === "recover" ? "Domains marked for deletion" : "Domains"}
        />
        <TableContainer>
          {tenants.loading ? <LoadingContainer><CircularProgress /></LoadingContainer> : <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              order={order}
              orderBy={String(orderBy)}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage + 1)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledRow
                      hover
                      onClick={() => handleClick(row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      deleted={!!row.ttl}
                    >
                      <TenantTableCell padding="checkbox">
                        <StyledCheckbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          deleted={!!row.ttl}
                        />
                      </TenantTableCell>
                      <TenantTableCell align="right" className="domain">{row.domain}</TenantTableCell>
                      <TenantTableCell align="right" className="system">{row.system}</TenantTableCell>
                      {row.ttl &&
                        <TenantTableCell align="right" className="system">{getLocaleTime(row.ttl)}</TenantTableCell>
                      }
                    </StyledRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>}
        </TableContainer>
        <PaginationContainer>

          <SimpleSelect {...{
            options: pages(),
            selected: pages().length ? (page + 1).toString() : "",
            setSelected: (value) => setPage(Number(value) - 1),
            label: "Page"
          }}></SimpleSelect>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            className="table-pagination"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaginationContainer>
      </Paper>
    </div>
  );
}

export default EnhancedTable;
