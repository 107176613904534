/* eslint-disable import/first */

export type Region =
  "us-east-1"       |
  "us-east-2"       |
  "us-west-1"       |
  "us-west-2"       |
  "ca-central-1"    |
  "eu-west-1"       |
  "eu-central-1"    |
  "eu-west-2"       |
  "eu-west-3"       |
  "eu-north-1"      |
  "ap-northeast-1"  |
  "ap-northeast-2"  |
  "ap-southeast-1"  |
  "ap-southeast-2"  |
  "ap-south-1"      |
  "sa-east-1"       |
  "us-gov-west-1"   |
  "us-gov-east-1";

export type Roles =
  "api-access"      |
  "api-read-only"   |
  "send-email-announcements"

export type Environment = {
  account: string;
  region: Region;
};

export type Endpoint = {
  url: string;
  roles: Roles[]
};

export const environments = <T extends Record<string, Environment>> (envs: T): T => envs;
import declaredEnvironments from "./environments";
export type Systems = keyof typeof declaredEnvironments;

export const resources = <T extends readonly string[]> (resources: T): T => resources;
import declaredResources from "./resources";
export type Resources = typeof declaredResources[number];

export type Combinations = `${Resources}/${Systems}`;

type Endpoints = { [K in Combinations]?: Endpoint;};
export const endpoints = (endpoints: Endpoints): Endpoints => endpoints;
