import useData from "../customHooks/useData";
import { globalEnvironment } from "../config/globalEnvironment";
import LineChart from "./LineChart";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { PrimaryButton } from "./Inputs";
import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { StyledAlert } from "./TenantsManagement";

const LoadtestsSip2WebRTC = (props: { path: string }) => {

  const [callsPerSecond, setCallsPerSecond] = useState("*");
  const [days, setDays] = useState(7);
  const [duration, setDuration] = useState(5);
  const [message, setMessage] = useState("Invalid Input.");
  const [open, setOpen] = useState(false);

  const response = useData({
    path: "loadtest/result", resource: "apiUrl", urls: [globalEnvironment.apiUrl.url], searchParams: {
      stage: "test",
      callsPerSec: callsPerSecond,
      testDurationInMins: duration,
      callScenario: "webrtc",
      days: days,
      instanceNumber: "1"
    }
  });

  const validateInputData = () => {
    console.log("days:", days);
    console.log("callsPerSec:", callsPerSecond);
    if ((days > 14) || ((days < 1))) {
      setMessage("Invalid number of days. Valid range is between 1 and 14.");
      setOpen(true);
      response.unloadData();
    } else if (((Number(callsPerSecond) > 6.0) || (Number(callsPerSecond) < 1.0)) && (callsPerSecond !== "*")) {
      setMessage("Invalid CallsPerSecond value. Use * to fetch all results, or use a value between 1.0 and 6.0.");
      setOpen(true);
      response.unloadData();
    } else if (Number(duration) <= 0) {
      setMessage("Invalid Duration value.");
      setOpen(true);
      response.unloadData();
    } else {
      response.setRefresh();
    }
  };

  return (
    <>
      <h2>Stage=test</h2>

      <TextField
        value={callsPerSecond}
        label="CallsPerSec"
        placeholder="Enter CallsPerSeconds value."
        inputProps={{
          maxLength: 4
        }}
        onChange={e => setCallsPerSecond(e.target.value)}
      />
      <br /><br />
      <TextField
        onChange={e => setDays(Number(e.target.value))}
        value={days}
        label="Days"
        placeholder="Number of days the loadtest data should be fetched."
      />
      <br /><br />
      <TextField
        onChange={e => setDuration(Number(e.target.value))}
        value={duration}
        label="Duration"
        placeholder="How long the runtime of the loadtest was."
      />
      <br /><br />
      <PrimaryButton onClick={validateInputData}>
        Fetch Test Results
      </PrimaryButton>

      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <StyledAlert onClose={() => setOpen(false)} severity={"error"}>
          {message}
        </StyledAlert>
      </Snackbar>

      {response.data.length ?
        response.data[0].success ?
          <div>
            <LineChart result={response.data[0].result}></LineChart>
          </div>
          :
          <div>
            Unable to fetch loadtest results. "Reason:" {response.data[0].reason}
          </div>
        : <></>
      }
      <br /><br />
      {response.loading &&
        <CircularProgress></CircularProgress>

      }
    </>
  );

};


export default LoadtestsSip2WebRTC;
