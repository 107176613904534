import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  DotProps
} from "recharts";

  interface Props {
    time?: string | undefined,
    avgCPU?: number | undefined,
    maxCPU?: number| undefined,
    avgConcurrentCalls?: number | undefined,
    maxConcurrentCalls?: number | undefined,
    avgConcurrentRtpCalls?: number | undefined,
    maxConcurrentRtpCalls?: number | undefined,
    avgConcurrentSessions?: number | undefined,
    maxConcurrentSessions?: number | undefined,
    avgSessionEstablishmentTime?: number | undefined,
    maxSessionEstablishmentTime?: number | undefined,
    avgConcurrentRecordings?: number | undefined,
    maxConcurrentRecordings?: number | undefined,
    usedMem?: number | undefined,
    usedThreads?: number | undefined,
    failedCalls?: number | undefined
}

interface DataProps {
  result: Props[]
} 

interface DotsWithValue extends DotProps {
  value?: number | undefined;
}

const CustomizedDot = (props: DotsWithValue) => {
  const { cx, cy, value } = props;

  if(cy && cx && value && value > 0) {

    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
        <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
      </svg>
    );
  } else {
    return (
      <></>
    );
  
  }
};

export default function App({ result }: DataProps) {
  
  // 99% width is bug in the recharts. It should have been 100%. 
  return (
    <div>
      <br/><br/>
      <ResponsiveContainer width="99%" height={500} >
        <LineChart
          data={result}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="avgCpu" stroke="blue" strokeWidth={3} activeDot={{ r: 8 }}/>
          <Line yAxisId="left" type="monotone" dataKey="maxCpu" stroke="red" />
          <>
            { hasDataFor(result, "avgConcurrentRecordings") ? <Line yAxisId="left" type="monotone" dataKey="avgConcurrentRecordings" strokeWidth={3} stroke="green" /> : <></> }
            { hasDataFor(result, "maxConcurrentRecordings") ? <Line yAxisId="left" type="monotone" dataKey="maxConcurrentRecordings" stroke="pink" /> : <></> }
            { hasDataFor(result, "avgConcurrentCalls") ? <Line yAxisId="left" type="monotone" dataKey="avgConcurrentCalls" strokeWidth={3} stroke="green" /> : <></> }
            { hasDataFor(result, "maxConcurrentCalls") ? <Line yAxisId="left" type="monotone" dataKey="maxConcurrentCalls" stroke="pink" /> : <></> }
            { hasDataFor(result, "avgConcurrentRtpCalls") ? <Line yAxisId="left" type="monotone" dataKey="avgConcurrentRtpCalls" strokeWidth={3} stroke="grey" /> : <></> }
            { hasDataFor(result, "maxConcurrentRtpCalls") ? <Line yAxisId="left" type="monotone" dataKey="maxConcurrentRtpCalls" stroke="orange" /> : <></> }
            { hasDataFor(result, "avgConcurrentSessions") ? <Line yAxisId="left" type="monotone" dataKey="avgConcurrentSessions" strokeWidth={3} stroke="darkred" /> : <></> }
            { hasDataFor(result, "maxConcurrentSessions") ? <Line yAxisId="left" type="monotone" dataKey="maxConcurrentSessions" stroke="indigo" /> : <></> }
            { hasDataFor(result, "avgSessionEstablishmentTime") ? <Line yAxisId="right" type="monotone" dataKey="avgSessionEstablishmentTime" strokeWidth={3} stroke="violet" /> : <></> }
            { hasDataFor(result, "maxSessionEstablishmentTime") ? <Line yAxisId="right" type="monotone" dataKey="maxSessionEstablishmentTime" stroke="purple" /> : <></> }
          </>
          <Line yAxisId="right" type="monotone" dataKey="usedMem" stroke="gold" strokeWidth={3} />
          <Line yAxisId="right" type="monotone" dataKey="usedThreads" stroke="brown" />
          <Line yAxisId="left" type="monotone" dataKey="failedCalls" strokeWidth={3} stroke="black" dot={<CustomizedDot />}/>
        </LineChart>
      </ResponsiveContainer>
      <br/><br/>
      <ul>
        <li> <b>Y-Axis(Left):</b> {getLeftLabels(result)} </li>
        <li> <b>Y-Axis(Right):</b> {getRightLabels(result)} </li>
      </ul>
    </div>
  );
}

const hasDataFor = (result: Props[], dataSet: String) => {
  return result.reduce((acc, current) => acc || !!current[dataSet as keyof Props], false);
};

const getLeftLabels = (result: Props[]) => {
  let labels = "avgCpu, maxCpu";
  if(hasDataFor(result, "failedCalls")) labels += ", failedCalls";
  if(hasDataFor(result, "avgConcurrentRecordings")) labels += ", avgConcurrentRecordings";
  if(hasDataFor(result, "maxConcurrentRecordings")) labels += ", maxConcurrentRecordings";
  if(hasDataFor(result, "avgConcurrentCalls")) labels += ", avgConcurrentCalls";
  if(hasDataFor(result, "maxConcurrentCalls")) labels += ", maxConcurrentCalls";
  if(hasDataFor(result, "avgConcurrentRtpCalls")) labels += ", avgConcurrentRtpCalls";
  if(hasDataFor(result, "maxConcurrentRtpCalls")) labels += ", maxConcurrentRtpCalls";
  if(hasDataFor(result, "avgConcurrentSessions")) labels += ", avgConcurrentSessions";
  if(hasDataFor(result, "maxConcurrentSessions")) labels += ", maxConcurrentSessions";
  return labels;
};

const getRightLabels = (result: Props[]) => {
  let labels = "";
  if(hasDataFor(result, "avgSessionEstablishmentTime")) labels += "avgSessionEstablishmentTime(ms), ";
  if(hasDataFor(result, "maxSessionEstablishmentTime")) labels += "maxSessionEstablishmentTime(ms), ";
  return labels + "usedMem(MB), usedThreads";
};
