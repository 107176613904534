export const defaultContract = {
  "customerId": "MyCompany",
  "customerDisplayName": "My Company",
  "costCenters": [
    { "domains": [ "mycompany.com" ] }
  ]
};

export const pricelist = {
  "options": {
    "splitArticles": []
  },
  "currency": "EUR",
  "articles":{
    "baseFee": {
      "disabled": true,
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 2800
            }
          ]
        }
      ]
    },
    "costCenterBaseFee":{
      "disabled": true
    },
    "support": {
      "disabled": true,
      "tariffs":[
        {
          "unit":"percent",
          "tariff":[
            {
              "price": 10
            }
          ]
        },
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 500
            }
          ]
        }
      ]
    },
    "support247": {
      "disabled": true,
      "tariffs":[
        {
          "unit":"percent",
          "tariff":[
            {
              "price": 15
            }
          ]
        },
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 1500
            }
          ]
        }
      ]
    },
    "costCenterSupport":{
      "disabled": true,
      "balanced": false,
      "input": {
        "unit": "cost",
        "inputArticles": [
          "ivr", "ivr_no_media", "ivr_media", "voip",
          "video", "siprec", "ivr_recording", "recording", "transcode"
        ]
      }
    },
    "costCenterSupport247":{
      "disabled": true,
      "balanced": false,
      "input": {
        "unit": "cost",
        "inputArticles": [
          "ivr", "ivr_no_media", "ivr_media", "voip",
          "video", "siprec", "ivr_recording", "recording", "transcode"
        ]
      }
    },
    "cacheFallback": {
      "disabled": true,
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 250
            }
          ]
        }
      ]
    },
    "sessionBorderController": {
      "disabled": true,
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 150
            }
          ]
        }
      ]
    },
    "RealTimeMonitoring": {
      "input": {
        "unit": "cost",
        "inputArticles": [
          "voip",
          "video",
          "ivr",
          "ivr_no_media",
          "ivr_media"
        ]
      },
      "tariffs":[
        {
          "unit":"percent",
          "tariff":[
            {
              "price": 20
            }
          ]
        }
      ]
    },
    "domains": {
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "next": 1,
              "price": 250
            },
            {
              "price": 100
            }
          ]
        }
      ]
    },
    "vpnConnection": {
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 75
            }
          ]
        }
      ]
    },
    "transitGatewayAttachment": {
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 75
            }
          ]
        }
      ]
    },
    "dxcon": {
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 75
            }
          ]
        }
      ]
    },
    "resolverEndpoint": {
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "price": 150
            }
          ]
        }
      ]
    },
    "voip":{
      "tariffs":[
        {
          "unit":"minutes",
          "tariff":[
            {
              "next":10000,
              "price":0.008
            },
            {
              "next":40000,
              "price":0.008
            },
            {
              "next":50000,
              "price":0.007
            },
            {
              "next":400000,
              "price":0.006
            },
            {
              "next":500000,
              "price":0.006
            },
            {
              "price":0.006
            }
          ]
        }
      ]
    },
    "transcode":{
      "tariffs":[
        {
          "unit":"minutes",
          "tariff":[
            {
              "price":0.015
            }
          ]
        }
      ]
    },
    "devices":{
      "bundles":{
        "demo": {
          "name": "demo",
          "qty": 75,
          "price": 0
        },
        "tier1": {
          "name": "tier1",
          "qty": 4500,
          "price": 8000
        },
        "tier2": {
          "name": "tier2",
          "qty": 9000,
          "price": 10500
        },
        "tier3": {
          "name": "tier3",
          "qty": 13500,
          "price": 15000
        },
        "tier4": {
          "name": "tier4",
          "qty": 18000,
          "price": 19000
        },
        "tier5": {
          "name": "tier5",
          "qty": 30000,
          "price": 29000
        }
      },
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "next":1000,
              "price":0.55
            },
            {
              "next":4000,
              "price":0.4
            },
            {
              "next":10000,
              "price":0.15
            },
            {
              "next":35000,
              "price":0.1
            },
            {
              "next":50000,
              "price":0.1
            },
            {
              "price":0.1
            }
          ]
        }
      ]
    },
    "video":{
      "tariffs":[
        {
          "unit":"minutes",
          "tariff":[
            {
              "next":10000,
              "price":0.015
            },
            {
              "next":40000,
              "price":0.014
            },
            {
              "next":50000,
              "price":0.013
            },
            {
              "next":9000000,
              "price":0.012
            },
            {
              "price":0.012
            }
          ]
        }
      ]
    },
    "message":{
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "next":1000000,
              "price":0.0004
            },
            {
              "next":4000000,
              "price":0.0001
            },
            {
              "price":0.00003
            }
          ]
        }
      ]
    },
    "analogue_alarm":{
      "tariffs":[
        {
          "unit":"minutes",
          "tariff":[
            {
              "next":10000,
              "price":0.04
            },
            {
              "next":40000,
              "price":0.025
            },
            {
              "next":50000,
              "price":0.02
            },
            {
              "next":900000,
              "price":0.01
            },
            {
              "price":0.01
            }
          ]
        }
      ]
    },
    "recording":{
      "tariffs":[
        {
          "unit":"minutes",
          "tariff":[
            {
              "price":0.0001
            }
          ]
        }
      ]
    },
    "trunks":{
      "tariffs":[
        {
          "unit":"quantity",
          "tariff":[
            {
              "next": 1,
              "price": 50
            },
            {
              "price": 25
            }
          ]
        }
      ]
    },
    "ivr":{
      "tariffs":[
        {
          "unit":"minutes",
          "tariff":[
            {
              "next":10000,
              "price":0.01
            },
            {
              "next":40000,
              "price":0.01
            },
            {
              "next":50000,
              "price":0.0075
            },
            {
              "next":400000,
              "price":0.0075
            },
            {
              "next":500000,
              "price":0.006
            },
            {
              "price":0.006
            }
          ]
        }
      ]
    }
  }
};
