
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileCopy from "@material-ui/icons/FileCopyOutlined";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { SimpleSelect } from "./Inputs";


import {
  EnhancedTableToolbar,
  EnhancedTableHead,
  Order,
  HeadCell,
  useStyles,
  getComparator,
  stableSort,
  LoadingContainer,
  StyledTableCell
} from "./TableComponents";
import { handleTableCellClick, parseRows, TableUserData, getLocaleTime } from "./tableUtils";
import { copyToClipboard } from "./utils";

import { UseDataResponse } from "../customHooks/useData";

const TenantsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 80px;
  ${({ scroll }: { scroll: boolean }) => scroll && `
    ::-webkit-scrollbar {
      display: none;
    }

  `}
`;

const IdCell = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  svg {
    fill: ${props => props.theme.colors.lightGray};
    transition: 0.5s ease all;
    :hover{
      fill: ${props => props.theme.colors.lightGreen};

    }
  }
  cursor: pointer;
`;
const IdContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
`;

const StyledCopyIcons = styled(FileCopyIcon)`
&.filled-file{
  fill: ${props => props.theme.colors.lightGreen};
}
`;
const headCells: HeadCell[] = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "firstName", numeric: false, disablePadding: true, label: "First Name" },
  { id: "lastName", numeric: true, disablePadding: false, label: "Last Name" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  { id: "created", numeric: true, disablePadding: false, label: "Created in" },
  { id: "system", numeric: true, disablePadding: false, label: "System" },
  { id: "domains", numeric: true, disablePadding: false, label: "Domains" },
];

interface TableProps {
  path?: string;
  searchString: string;
  setSelected: (value: TableUserData[]) => void;
  selected: TableUserData[],
  tenant?: string,
  reload: boolean,
  deleteRequests: any[];
  users: UseDataResponse;
  setSnack: (message: string, severity: string) => void;
}

function EnhancedTable({ searchString, setSelected, selected, reload, tenant, deleteRequests, users, setSnack }: TableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof TableUserData>("created");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastClicked, setLastClicked] = useState<undefined | number>(undefined);

  const rows = parseRows({ selected, users, searchString, tenant, deleteRequests, returnMarked: false });
  const sortedRows = stableSort(rows, getComparator(order, orderBy));

  useEffect(() => { setPage(0); }, [searchString]);


  useEffect(() => {
    if (sortedRows.length) {
      if (selected.length) {

        const lastSelected = selected[selected.length - 1];
        const indexOfLastSelected = sortedRows.findIndex((value) => value.id === lastSelected.id);
        const currentPage = Math.ceil((indexOfLastSelected + 1) / rowsPerPage);

        setPage(currentPage - 1);
      }
    } else {
      // setPage(0);
    }

  }, [selected, rowsPerPage, sortedRows]);


  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TableUserData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(rows);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string) => {
    const newSelected: TableUserData[] = handleTableCellClick({ id, rows, selected });
    if (newSelected) {
      return setSelected(newSelected);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.map((n: TableUserData) => n.id).indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  type Page = {
    value: string;
    label: string;
  };

  const pages = (): Array<Page> => {
    const numOfPages = Math.ceil(rows.length / rowsPerPage);
    const p = [];
    for (let i = 1; i <= numOfPages; i++) {
      p.push({ value: i.toString(), label: i.toString() });
    }
    return p;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} tableTitle={tenant && tenant !== "All" ? "Assigned users for " + tenant.replace("tenant_", "") : "Assigned users"} />
        <TableContainer>
          {users.loading ? <LoadingContainer><CircularProgress /></LoadingContainer> : <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={String(orderBy)}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sortedRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage + 1)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" id={labelId} scope="row" padding="none" className="id">
                        <IdCell>
                          <IdContainer>{row.id}</IdContainer>

                          {lastClicked === index ?
                            <StyledCopyIcons
                              className="filled-file"
                              onClick={(e) => {
                                e.stopPropagation();
                                setLastClicked(index);
                                const { success, reason } = copyToClipboard(row.id);
                                if (success) {
                                  setSnack("Copied Id to clipboard", "success");
                                } else if (reason) {
                                  setSnack(reason, "error");
                                }
                              }} {...{ lastClicked: true }} />
                            :
                            <FileCopy onClick={(e) => {
                              e.stopPropagation();
                              setLastClicked(index);
                              const { success, reason } = copyToClipboard(row.id);
                              if (success) {
                                setSnack("Copied Id to clipboard", "success");
                              } else if (reason) {
                                setSnack(reason, "error");
                              }
                            }} />
                          }

                        </IdCell>
                      </StyledTableCell>
                      <StyledTableCell align="right" className="firstName">{row.firstName}</StyledTableCell>
                      <StyledTableCell align="right" className="lastName">{row.lastName}</StyledTableCell>
                      <StyledTableCell align="right" className="email">{row.email}</StyledTableCell>
                      <StyledTableCell align="right" className="created">{getLocaleTime(row.created)}</StyledTableCell>
                      <StyledTableCell align="right" className="system">{row.system}</StyledTableCell>
                      {row.tenants?.length &&
                        <StyledTableCell align="right" className="tenants"><TenantsContainer {...{ scroll: row.tenants.length <= 1 }}>
                          {row.tenants.map(t =>
                            typeof t === "string" ?
                              <p key={t}>
                                {t.replace("tenant_", "")}
                              </p>
                              :
                              <Tooltip title={t.roles.join(", ")} placement="top-end">
                                <p key={t.domain}>
                                  {t.domain.replace("tenant_", "")}
                                </p>
                              </Tooltip>
                          )}
                        </TenantsContainer></StyledTableCell>
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>}
        </TableContainer>
        <PaginationContainer>

          <SimpleSelect {...{
            options: pages(),
            selected: pages().length ? (page + 1).toString() : "",
            setSelected: (value) => setPage(Number(value) - 1),
            label: "Page"
          }}></SimpleSelect>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            className="table-pagination"
            count={sortedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaginationContainer>
      </Paper>
    </div>
  );
}

export default EnhancedTable;
