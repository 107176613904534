// import { PropsWithChildren } from "react";

// import DataProvider, { useDeploymentsData } from "./DataProvider";
// import Ongoing from "./Ongoing";
// import Overview from "./Overview";
// import styled from "../../util/styled";
// import {SpinnerContainer, Spinner} from "../Spinner";
// const Container = styled.div`
//   display: flex;
//   flex-direction: column;

//   gap: 1.5em;
// `;

// const DataLoadingView = (props: PropsWithChildren<{}>) => {
//   const data = useDeploymentsData();

//   const conditions = [
//     data.processes != null,
//     data.stacks != null,
//     data.colors != null,
//     data.transitions != null
//   ];

//   const loaded = conditions.every(it => it);
//   if (loaded) {
//     return <>
//       {props.children}
//     </>;
//   }
//   else {
//     return <SpinnerContainer>
//       <Spinner
//         size="4em"
//         $color={data.config.colors.brand.accent}
//       />
//     </SpinnerContainer>;
//   }
// };

export default function Deployments() {

  return <h1>
    This page currently disabled
  </h1>;

  /*
  return <DataProvider>
    <DataLoadingView>
      <Container>
        <Ongoing />
        <Overview />
      </Container>
    </DataLoadingView>
  </DataProvider>;
  */
}
