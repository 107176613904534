import { globalEnvironment } from "../config/globalEnvironment";

import { SignatureV4 } from "@aws-sdk/signature-v4";
import { Sha256 } from "@aws-crypto/sha256-js";
import {Credentials, HttpRequest} from "@aws-sdk/types";

export const copyToClipboard = (content: string) => {
  try {
    navigator.clipboard.writeText(content);
    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false, reason: "Failed to copy", error };
  }
};


export const getUrl = (resource: string, system: string) => {

  const globalKeyName = Object.keys(globalEnvironment).find(v => v.startsWith("tenantApi") && v.endsWith(system));
  if (!globalKeyName) return "";
  const globalKey = globalEnvironment[globalKeyName];

  return globalKey.url;
};

interface SignedFetchProps {
  credentials: Credentials,
  region: string;
  service: string;
  body?: string,
  method?: string;
}

export const signedFetch = ({ credentials, region, service, body, method = "GET" }: SignedFetchProps) =>
  async (input: RequestInfo, initHeaders?: Record<string, string>) => {
    const Signer = new SignatureV4({
      credentials,
      region,
      service,
      sha256: Sha256,
    });

    const url = new URL(typeof input === "string" ? input : input.url);
    let query = {};
    url.searchParams.forEach((value, key) => query = {...query, [key]:value} );
    const req: HttpRequest = {
      hostname: url.hostname,
      path: url.pathname,
      protocol: url.protocol,
      query,
      method,
      body,
      headers: {
        host: url.host,
        ...initHeaders
      }
    };
    const signed : HttpRequest = await Signer.sign(req);
    const response = await fetch(input, {
      headers: { ...signed.headers, ...initHeaders },
      method,
      body
    });
    return response;
  };
export interface StoredCredentials extends Credentials {
  accountId: string;
  roleName: string;
};

export function isEmpty(obj:object) {
  return Object.keys(obj).length === 0;
}

export const billingCredentials = (credentials:string) => {
  const creds = JSON.parse(credentials)
    .filter(
      (c:StoredCredentials) =>
        (c.accountId === "246090836764" && c.roleName === "billing-access")
        || (c.accountId === "246090836764" && c.roleName === "devops-billing")
    ).map((c:StoredCredentials) => [c.roleName, c]);
  return Object.fromEntries(creds);
};

export const devopsCredentials = (credentials:string) => {
  const creds = JSON.parse(credentials)
    .filter(
      (c:StoredCredentials) =>
        (c.accountId === "292435300779" && c.roleName === "api-access")
    ).map((c:StoredCredentials) => [c.roleName, c]);
  return Object.fromEntries(creds);
};
