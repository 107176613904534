import { Credentials } from "../types";
import { globalEnvironment } from "../config/globalEnvironment";
import { Resource } from "../config/globalEnvTypes";

interface FindEndpointsArgs {
  resource: string;
  accountId: string;
  urls?: string[]
}

export const findEndpoints = ({ resource, accountId, urls }: FindEndpointsArgs) => {

  const keys = Object.keys(globalEnvironment);
  return keys.filter(key => {
    
    
    if (urls && urls.length) {
      return key.startsWith(resource) &&
        globalEnvironment[key].accountId === accountId &&
        urls.includes(globalEnvironment[key].url);
    }

    return key.startsWith(resource) && globalEnvironment[key].accountId === accountId;
  }).map(key => {
    const response = ({ endpoint: globalEnvironment[key], system: "" });

    const systemWithDash = key.match(/-.*/);
    if (systemWithDash && systemWithDash[0]) {
      response.system = systemWithDash[0].replace("-", "");
    }

    return response;
  });
};

interface Args {
  resource: string;
  system?: string;
  urls?: string[];
}

export interface CredentialsWithEndpoints extends Credentials {
  endpoints: {
    endpoint: Resource;
    system: string
  }[];

}

export type CredentialsResponse = Array<CredentialsWithEndpoints>;

export const credentialsFinder = ({ resource, system, urls }: Args): CredentialsResponse => {
  // should be able to use system instead of url
  // and urls should supplant the need for the globalEnvironment variables
  //get credentials
  const credentialsString = localStorage.getItem("credentials");
  if (!credentialsString) return [];

  const credentials: Credentials[] = JSON.parse(credentialsString);
  let roleList: string[] = [];
  if (resource && system) {
    const res = globalEnvironment[`${resource}-${system}`];
    if (res.roles) roleList = res.roles;

  } else {
    //filter by specified resource
    Object.keys(globalEnvironment)
      .filter(res => res.startsWith(resource))
      .forEach(res => {
        const currentRes = globalEnvironment[res];

        if (!currentRes || currentRes.roles === undefined) return;

        if (typeof currentRes.roles === "object") {
          const rList = currentRes.roles;
          rList.forEach(r => roleList.includes(r) ? null : roleList.push(r));
        }
      });
  }
  return credentials
    .filter(cred => roleList.find(roleName => cred.roleName.startsWith(roleName)))
    .map(cred => ({ ...cred, endpoints: findEndpoints({ resource, accountId: cred.accountId, urls }) }));
};
