import { PropsWithChildren, useMemo, useReducer } from "react";
import { Route } from "wouter";
import Auth from "./components/Auth";
import { MainContext, reducer, initialState } from "./contexts/MainContext";
import { useCookies } from "react-cookie";
import useLocalStorage from "react-use-localstorage";
import MainWrapper from "./components/MainWrapper";
import UsersManagement from "./components/UsersManagement";
import TenantsManagement from "./components/TenantsManagement";
import LoadtestsPrompt from "./components/LoadtestsPrompt";
import LoadtestsCall from "./components/LoadtestsCall";
import LoadtestsTt92 from "./components/LoadtestsTT92";
import LoadtestsSip2WebRTC from "./components/LoadtestsWebRTC";
import LoadtestsSipRec from "./components/LoadtestsSipRec";
import InvoicingReport from "./components/invoicing/InvoicingReport";
import InvoicingContract from "./components/invoicing/InvoicingContract";
import InvoicingPricelist from "./components/invoicing/InvoicingPricelist";
import InvoicingSimulator from "./components/invoicing/InvoicingSimulator";
import Announcements from "./components/Announcements";
import Deployments from "./components/deployments/Deployments";
import CodeCoverage from "./components/CodeCoverage";

import { Credentials } from "./types";
import { billingCredentials, isEmpty } from "./components/utils";

type RouteComponentProps = PropsWithChildren<{
  path: string;
}>;

const WrapperWithRoute = (props: RouteComponentProps) => <Route path={props.path}>
  <MainWrapper {...props}>
    {props.children}
  </MainWrapper>
</Route>;

const Dash = (props: RouteComponentProps) => <WrapperWithRoute path={props.path}>
  <a href="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=servicesDashboard-test&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTI5MjQzNTMwMDc3OSIsIlUiOiJ1cy1lYXN0LTFfakYybTdOWGhsIiwiQyI6IjdyM3UyY3Fib2M3YjV2YWZlOGplbWlnOHM0IiwiSSI6InVzLWVhc3QtMToyMDc1YzIxNy02NTkzLTQyNWItYmU4MC1hMWM5OGZlOTY2NDQiLCJPIjoiYXJuOmF3czppYW06OjI5MjQzNTMwMDc3OTpyb2xlL3NlcnZpY2Utcm9sZS9DbG91ZFdhdGNoRGFzaGJvYXJkLVJlYWRPbmx5QWNjZXNzLUFMTC01VU9RTlBIRiIsIk0iOiJTU08ifQ==">See the dashboards</a>
</WrapperWithRoute>;
const Home = (props: RouteComponentProps) => <WrapperWithRoute path={props.path}>
  <></>
</WrapperWithRoute>;

export default function App() {
  const [globalState, globalDispatch] = useReducer(reducer, initialState);
  const [cookies] = useCookies(["authenticated"]);
  const [credentials] = useLocalStorage("credentials");

  const roles = useMemo(() => {
    if (credentials && cookies.authenticated) {
      const json = JSON.parse(credentials);
      const roles: string[] = [];
      json.forEach((cred: Credentials) => roles.push(cred.roleName));
      return roles;
    }
    return [];
  }, [cookies.authenticated, credentials]);


  return (<>
    <MainContext.Provider
      value={{
        globalState,
        globalDispatch,
        roles
      }}
    >

      <Auth></Auth>
      {cookies.authenticated && credentials &&
        <>
          <Home path="/" />
          <Dash path="/dashboard" />
          {roles?.includes("api-access") &&
            <WrapperWithRoute path="/deployments">
              <Deployments/>
            </WrapperWithRoute>
          }
          {roles?.includes("api-access") &&
            <WrapperWithRoute path="/users/:rest">
              <Route path="/users/assigned">
                <UsersManagement path="assigned" />
              </Route>
              <Route path="/users/unassigned">
                <UsersManagement path="unassigned" />
              </Route>
            </WrapperWithRoute>
          }
          {roles?.includes("api-access") &&
            <WrapperWithRoute path="/tenants/:rest">
              <Route path="/tenants/create">
                <TenantsManagement path="create" />
              </Route>
              <Route path="/tenants/overview">
                <TenantsManagement path="overview" />
              </Route>
              <Route path="/tenants/recover">
                <TenantsManagement path="recover" />
              </Route>
            </WrapperWithRoute>
          }
          {roles?.includes("api-access") &&
            <WrapperWithRoute path="/loadtests/:rest">
              <Route path="/loadtests/prompt">
                <LoadtestsPrompt path="prompt"></LoadtestsPrompt>
              </Route>
              <Route path="/loadtests/call">
                <LoadtestsCall path="call"></LoadtestsCall>
              </Route>
              <Route path="/loadtests/tt92">
                <LoadtestsTt92 path="tt92"></LoadtestsTt92>
              </Route>
              <Route path="/loadtests/siprec">
                <LoadtestsSipRec path="siprec"></LoadtestsSipRec>
              </Route>
              <Route path="/loadtests/webrtc">
                <LoadtestsSip2WebRTC path="webrtc"></LoadtestsSip2WebRTC>
              </Route>
            </WrapperWithRoute>
          }
          {!isEmpty(billingCredentials(credentials)) &&
            <WrapperWithRoute path="/invoicing/:rest">
              <Route path="/invoicing/report">
                <InvoicingReport path="report"></InvoicingReport>
              </Route>
              <Route path="/invoicing/pricelist">
                <InvoicingPricelist path="pricelist"></InvoicingPricelist>
              </Route>
              <Route path="/invoicing/contract">
                <InvoicingContract path="contract"></InvoicingContract>
              </Route>
              <Route path="/invoicing/simulator">
                <InvoicingSimulator path="simulator"></InvoicingSimulator>
              </Route>
            </WrapperWithRoute>
          }
          {roles?.includes("api-access") &&
            <Route path="/coverage/:id*">
              <CodeCoverage/>
            </Route>
          }
          {roles?.includes("send-email-announcements") &&
            <WrapperWithRoute path="/announcements">
              <Announcements />
            </WrapperWithRoute>
          }
        </>
      }

    </MainContext.Provider>
  </>
  );
}
