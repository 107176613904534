import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";


type StyledRowProps = {
  deleted?: boolean
}

export const StyledRow = styled(TableRow) <StyledRowProps>`
  &.Mui-selected {
  ${props => props.deleted && `background-color: ${props.theme.colors.lighterGreen}`};

  }
  &.Mui-selected:hover{
    background-color: ${props => props.deleted && props.theme.colors.lighterGreen};
  }
  &:hover, .Mui-selected:hover{
    background-color: ${props => props.deleted && props.theme.colors.lightGray};
  }
`;

export const StyledCheckbox = styled(Checkbox) <StyledRowProps>`
    &.Mui-checked svg {
        ${props => props.deleted && `
    
    fill: ${props.theme.colors.green}
    `}
    
  }
`;


export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface CustomProps {
  action?: string;
  highlight: boolean;
};

const StyledToolbar = styled(Toolbar) <CustomProps>`
  ${props => props.highlight ?
    props.action === "recover" ?
      `
      color: ${props.theme.colors.lightGreen};
      background-color: ${lighten(props.theme.colors.lightGreen, 0.85)}
      ` :
      `
      color: ${props.theme.colors.lightRed};
      background-color: ${lighten(props.theme.colors.lightRed, 0.85)}
    ` : undefined}
`;

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: "1 1 100%",
    },
  }),
);

export const StyledTableCell = styled(TableCell)`
  padding: 0 16px;
`;

interface EnhancedTableToolbarProps {
  numSelected: number;
  tableTitle: string;
  handleDelete?: () => void;
  handleRecover?: () => void;
  action?: "recover";
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, action, tableTitle } = props;
  const classes = useToolbarStyles({ action });

  return (
    <StyledToolbar
      action={action}
      highlight={numSelected > 0}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h3" id="tableTitle" component="div">
          {tableTitle}
        </Typography>
      )}

    </StyledToolbar>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }),
);

export type Order = "asc" | "desc";



export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
  action?: "recover";
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          {numSelected || numSelected === 0 ?
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
            : <></>
          }
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={String(headCell.id)}
            align={"right"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | string[] | undefined | boolean | {} }, b: { [key in Key]: number | boolean | string | string[] | undefined | {} }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {

    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

