import { GlobalEnv } from "./globalEnvTypes";

export const globalEnvironment: GlobalEnv = {
  apiUrl: {
    url: import.meta.env.VITE_OWN_API_URL as string,
    accountId: "292435300779",
    roles: ["api-access"]
  },
  "tenantApi-dev": {
    url: "https://api.dev.iotcomms.io/tenant",
    accountId: "292435300779",
    roles: ["api-access"]
  },
  "tenantApi-test": {
    url: "https://api.test.iotcomms.io/tenant",
    accountId: "292435300779",
    roles: ["api-access"]
  },
  "tenantApi-stage": {
    url: "https://api.stage.iotcomms.io/tenant",
    accountId: "902090632057", // Note: New accountId for new stage in eu-north-1
    roles: ["api-access"]
  },
  "tenantApi-prod-eu-north-1": {
    url: "https://api.prod-eu-north-1.iotcomms.io/tenant",
    accountId: "344822328383",
    roles: ["api-access", "api-read-only"],
    region: "eu-north-1"
  },
  "tenantApi-prod-us-east-1": {
    url: "https://api.prod-us-east-1.iotcomms.io/tenant",
    accountId: "344822328383",
    roles: ["api-access", "api-read-only"],
    region: "us-east-1"
  }
};
