export interface Typography {
  [key: string]: {
    [key: string]: string | number
  } | string | number
}

const typography: Typography = {
  fontFamily: "\"futura-pt-bold\", Sans-serif",
  fontSize: 16,
  lineHeight: "1em",
  button: {
    fontFamily: "\"futura-pt-bold\", Sans-serif",
    fontSize: 16,
    lineHeight: "1em",
    fontWeight: "bold"
  },
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: "-0.24px"
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: "-0.24px"
  },
  h3: {
    color: "#212121",
    fontFamily: "futura-pt-bold, Sans-serif",
    fontSize: "30px",
    fontWeight: "600",
    lineHeight: "1.2em",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "-0.06px"
  },
  h5: {
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: 0.5,
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px"
  },
  overline: {
    fontWeight: 500
  }
};

export default typography;
