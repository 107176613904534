import React, { Dispatch } from "react";
import { Action } from "../types";
interface GlobalState {
  authenticated: boolean;
  permissions: Object[];
  tenant: string;
  account: string;
};

export const initialState: GlobalState = {
  authenticated: false,
  permissions: [],
  tenant: "",
  account: ""
};

interface Actions {
  [key: string]: () => GlobalState;
}

export const reducer = (state: GlobalState, action: Action): GlobalState => {
  const actions: Actions = {
    "AUTHENTICATED": () => {
      return {
        ...state,
        authenticated: !state.authenticated,
      };
    },
    "GET_PERMISSIONS": () => {
      return {
        ...state,
        permissions: action.value,
      };
    },
    "SET_TENANT": () => {
      return {
        ...state,
        tenant: action.value,
      };
    },
    "SELECT_ACCOUNT": () => {
      return {
        ...state,
        account: action.value,
      };
    },
    "default": () => ({ ...state }),
  };

  return actions[action.type] ? actions[action.type]() : actions["default"]();
};

interface Context {
  globalState: GlobalState;
  globalDispatch: Dispatch<Action>;
  roles: string[];
}

const contOb: Context = { globalState: initialState, globalDispatch: () => ({ ...initialState, type: "default" }), roles: [] };


export const MainContext = React.createContext(contOb);
