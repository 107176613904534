import useData from "../customHooks/useData";
import { globalEnvironment } from "../config/globalEnvironment";
import LineChart from "./LineChart";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { PrimaryButton } from "./Inputs";
import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {StyledAlert} from "./TenantsManagement";


const LoadtestsPrompt = (props: { path: string }) => {

  const [callsPerSecond, setCallsPerSecond] = useState("*");
  const [days, setDays] = useState(7);
  const [message, setMessage] = useState("Invalid Input.");
  const [open, setOpen] = useState(false);

  const response1 = useData({
    path: "loadtest/result", resource: "apiUrl", urls: [globalEnvironment.apiUrl.url], searchParams: {
      stage: "test",
      callsPerSec: callsPerSecond,
      testDurationInMins: "15",
      callScenario: "ivr-greeting",
      days: days,
      instanceNumber: "1"
    }
  });

  const response2 = useData({
    path: "loadtest/result", resource: "apiUrl", urls: [globalEnvironment.apiUrl.url], searchParams: {
      stage: "test",
      callsPerSec: callsPerSecond,
      testDurationInMins: "15",
      callScenario: "ivr-greeting",
      days: days,
      instanceNumber: "2"
    }
  });


  const validateInputData = () => {
    console.log("days:",days);
    console.log("callsPerSec:", callsPerSecond);
    if ( (days > 14) || ((days < 1)) ) {
      setMessage("Invalid number of days. Valid range is between 1 and 14.");
      setOpen(true);
      response1.unloadData();
      response2.unloadData();
    } else if ( ((Number(callsPerSecond) > 6.0) || (Number(callsPerSecond) < 1.0)) && (callsPerSecond !== "*") ) {
      setMessage("Invalid CallsPerSecond value. Use * to fetch all results, or use a value between 1.0 and 6.0.");
      setOpen(true);
      response1.unloadData();
      response2.unloadData();
    } else
      response1.setRefresh();  
    response2.setRefresh();
  };

  return (
    <>
      <h2>Stage=test,TestDuration=15m,callDuration=30s</h2>
      
      <TextField 
        value={callsPerSecond}
        label="CallsPerSec"
        placeholder="Enter CallsPerSeconds value."
        inputProps={{
          maxLength: 4
        }}
        onChange={e => setCallsPerSecond(e.target.value)}
      />
      <br/><br/>
      <TextField 
        onChange={e => setDays(Number(e.target.value))}
        value={days}
        label="Days"
        placeholder="Number of days the loadtest data should be fetched."
      />
      <br/><br/>
      <PrimaryButton onClick={validateInputData}>
        Fetch Specific Test Result.
      </PrimaryButton>
      
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <StyledAlert onClose={() => setOpen(false)} severity={"error"}>
          {message}
        </StyledAlert>
      </Snackbar>
      
      {response1.data.length ?
        response1.data[0].success  ?
          <div>
            <LineChart result={response1.data[0].result}></LineChart>
          </div>
          :
          <div>
            Unable to fetch loadtest results. "Reason:" {response1.data[0].reason}
          </div>
        : <></>
      } 
      <br/><br/>
      {response1.loading && 
            <CircularProgress></CircularProgress>
      }

      <br/><br/>
      {response2.data.length ?
        response2.data[0].success  ?
          <div>
            <LineChart result={response2.data[0].result}></LineChart>
          </div>
          :
          <div>
            Unable to fetch loadtest results. "Reason:" {response2.data[0].reason}
          </div>
        : <></>
      } 
      <br/><br/>
      {response2.loading && 
            <CircularProgress></CircularProgress>
      }

    </>
  );

};


export default LoadtestsPrompt;

