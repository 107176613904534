import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import { Spinner } from "./Spinner";
import { Systems } from "@config";
import { request, styled, Ref, between } from "@util";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  min-width: 70%;

  padding: 2em;
  border-radius: 1em;
  background-color: #f7f7f7;
  box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.20);
`;

const Title = styled.h1`
  margin: 0;
`;

const TitleText = styled.p`
  margin: 0%;
`;

const TitleContainer = styled.div`
  margin: 1em;
`;


const FullWidthField = styled(TextField)`
  width: 100%;
`;

type ButtonColorProps = {
  $color: string;
};

const FilledButton = styled(Button) <ButtonColorProps>`
  background-color: ${props => props.$color};

  &:hover {
    filter: brightness(110%);
    background-color: ${props => props.$color};
  }
`;

const OutlinedButton = styled(Button) <ButtonColorProps>`
  color: ${props => props.$color};
  border-color: ${props => props.$color};

  &:hover {
    color: ${props => props.$color};
    border-color: ${props => props.$color};
  }
`;

type SelectedSystem = {
  label: string;
  value: Systems;
};

type Mail = {
  system: Systems;
  subject: string;
  message: string;
};

const assumeImpossible = () => {
  throw new Error("Reached what was assumed to be an impossible code path! Something is very wrong!");
};

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export default function Announcements() {
  const sendDialogRef = useRef<SendDialogInterface>();

  const [system, setSystem] = useState<SelectedSystem | null>(null);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [systemError, setSystemError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const availabilityQuery = useQuery({
    staleTime: Infinity,
    queryKey: ["tenant", "announcements", "available"],
    queryFn: () => request("TenantAPI", "/announcement/email/available", { requiredRole: "send-email-announcements" }).map(promise => promise
      .then(it => [it.system, between(it.status, 200, 299)] as const)
      .catch(() => [undefined, false] as const)
    ).all()
  });

  const handleBeginSend = () => {
    let allPresent = true;
    if (system == null) {
      allPresent = false;
      setSystemError(true);
    }

    if (subject == null || subject === "") {
      allPresent = false;
      setSubjectError(true);
    }

    if (message == null || message === "") {
      allPresent = false;
      setMessageError(true);
    }

    if (!allPresent) {
      return;
    }

    sendDialogRef.current?.initiate({
      system: system?.value ?? assumeImpossible(),
      subject: subject,
      message: message
    });
  };

  return <Container>
    {availabilityQuery.isFetched ?
      <Card>
        <TitleContainer>
          <Title>Announce</Title>
          <TitleText>Send an email to all registered users in a system</TitleText>
        </TitleContainer>
        <Autocomplete
          value={system}
          onChange={(event, value) => {
            setSystem(value);
            if (systemError) {
              setSystemError(false);
            }
          }}
          disablePortal
          options={availabilityQuery.data!
            .filter(([system, available]) => available && system != null)
            .map(([system]) => ({ label: system as string, value: system as Systems }))}
          renderInput={(params) => <TextField error={systemError} helperText={systemError ? "Required" : undefined} {...params} label="System" />}
        />
        <FullWidthField
          label="Subject"
          value={subject}
          error={subjectError}
          helperText={subjectError ? "Required" : undefined}
          onChange={(event) => {
            setSubject(event.currentTarget.value);
            if (subjectError && event.currentTarget.value != null && event.currentTarget.value !== "") {
              setSubjectError(false);
            }
          }}
        />
        <FullWidthField
          label="Message"
          value={message}
          error={messageError}
          helperText={messageError ? "Required" : undefined}
          multiline
          rows={6}
          onChange={(event) => {
            setMessage(event.currentTarget.value);
            if (messageError && event.currentTarget.value != null && event.currentTarget.value !== "") {
              setMessageError(false);
            }
          }}
        />
        <FilledButton
          $color="#00659A"
          size="large"
          variant="contained"
          onClick={handleBeginSend}
        >
          Send
        </FilledButton>
        <SendDialog
          reference={sendDialogRef}
        />
      </Card>
      :
      <Spinner
        size="4em"
        $color="#44BBA4"
      />
    }
  </Container>;
}

const SendDialogContainer = styled.div`
  padding: 2em;
  min-height: 20em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SendConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

const SendConfirmationHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SendConfirmationFooter = styled.div`
  display: flex;
  gap: 1em;
`;

const SendResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type SendDialogInterface = {
  initiate: (mail: Mail) => void;
};

type SendDialogProps = {
  reference: Ref<SendDialogInterface>;
};

type MailPreview = {
  recipients: number;
  estimatedSeconds: number;
};

const SendDialog = (props: SendDialogProps) => {
  const sendUnlockTimeSeconds = 20;
  const untrackedSendRequestThreshold = 25;

  const [mail, setMail] = useState<Mail>();
  const [open, setOpen] = useState(false);
  
  const [sendButtonText, setSendButtonText] = useState(`Unlocks in ${sendUnlockTimeSeconds}`);
  const [sendAvailable, setSendAvailable] = useState(false);
  const [sendAvailableTime, setSendAvailableTime] = useState<number>();

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [sendingError, setSendingError] = useState<string>();

  const previewQuery = useQuery({
    enabled: open && mail != null,
    staleTime: 5 * 60 * 1000,
    queryKey: ["tenant", "announcements", "preview", { system: mail?.system }],
    queryFn: () => request(`TenantAPI/${mail?.system ?? assumeImpossible()}`, "/announcement/email/preview", { requiredRole: "send-email-announcements" })
      .then(it => it.body as MailPreview)
  });

  // Reset relevant state when mail changes
  useEffect(() => {
    setSending(false);
    setSent(false);
    setSendingError(undefined);

    setSendAvailable(false);
    setSendButtonText(`Unlocks in ${sendUnlockTimeSeconds}`);

    if (previewQuery.isFetched) {
      setSendAvailableTime(Date.now() + (sendUnlockTimeSeconds * 1000));
    }
  }, [mail, previewQuery.isFetched, setSending, setSent, setSendAvailable, setSendAvailableTime, setSendButtonText]);

  // Manage countdown for unlocking the send button
  useEffect(() => {
    if (sendAvailable) {
      return;
    }

    if (open && previewQuery.isFetched && sendAvailableTime != null) {
      const handle = setInterval(() => {
        const now = Date.now();
        if (now < sendAvailableTime) {
          setSendButtonText(`Unlocks in ${Math.ceil((sendAvailableTime - now) / 1000)}`);
        }
        else {
          setSendButtonText("Send");
          setSendAvailable(true);
        }
      }, 1000);

      return () => clearInterval(handle);
    }
  }, [open, previewQuery.isFetched, sendAvailableTime, sendAvailable, setSendButtonText]);

  const handleClose = () => {
    if (sending) {
      return;
    }

    setMail(undefined);
    setOpen(false);
  };

  const makeSendRequest = () => request(`TenantAPI/${mail?.system ?? assumeImpossible()}`, "/announcement/email", {
    requiredRole: "send-email-announcements",
    method: "POST",
    body: JSON.stringify({
      subject: mail?.subject,
      textContent: mail?.message
    }),
    headers: {
      "Content-Type": "application/json"
    }
  });

  const handleSend = () => {
    const estimatedSeconds = previewQuery.data?.estimatedSeconds ?? assumeImpossible();
    if (estimatedSeconds > untrackedSendRequestThreshold) {
      setSending(true);

      let requestError: unknown;
      const requestPromise = makeSendRequest()
        .catch(err => { requestError = err; });

      Promise.race([delay(3 * 1000), requestPromise]).then(() => {
        setSending(false);
        setSent(true);
        if (requestError != null) {
          setSendingError(String(requestError));
        }
      });
    }
    else {
      setSending(true);

      makeSendRequest()
        .then(() => {
          setSent(true);
        })
        .catch(error => {
          setSendingError(error);
        })
        .finally(() => {
          setSending(false);
        });
    } 
  };

  props.reference.current = {
    initiate: (mail) => {
      setMail(mail);
      setOpen(true);
    }
  };

  const sendConfirmationSegment = <>
    <SendConfirmationContainer>
      <SendConfirmationHeader>
        <Title>Are you sure?</Title>
        <TitleText>This will send an email to <b>{previewQuery.data?.recipients}</b> user(s)!</TitleText>
      </SendConfirmationHeader>
      <SendConfirmationFooter>
        <OutlinedButton
          $color="#747474"
          size="large"
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </OutlinedButton>
        <FilledButton
          $color="#00659A"
          size="large"
          variant="contained"
          disabled={!sendAvailable}
          onClick={handleSend}
        >
          {sendButtonText}
        </FilledButton>
      </SendConfirmationFooter>
    </SendConfirmationContainer>
  </>;

  const mailSentSegment = sendingError != null ? <SendResultContainer>
    <Title>Error</Title>
    <p>{sendingError}</p>
  </SendResultContainer> : (previewQuery.data?.estimatedSeconds ?? 0) > untrackedSendRequestThreshold ? <SendResultContainer>
    <Title>Announcement process started</Title>
    <p>Due to limitations in API Gateway, we will not receive a response wether or not all messages were sent out successfully</p>
    <p>If you want to check this yourself you can do so by visiting the AWS Console and checking if the lambda exited successfully or not</p>
    <OutlinedButton
      $color="#747474"
      size="large"
      variant="outlined"
      onClick={handleClose}
    >
      Close
    </OutlinedButton>
  </SendResultContainer> : <SendResultContainer>
    <Title>Announcement made!</Title>
    <p>The message has been sent out to all recipients</p>
    <OutlinedButton
      $color="#747474"
      size="large"
      variant="outlined"
      onClick={handleClose}
    >
      Close
    </OutlinedButton>
  </SendResultContainer>;

  return <Dialog open={open} fullWidth={true} onClose={handleClose}>
    <SendDialogContainer>
      {previewQuery.isFetched ?
        sent ?
          mailSentSegment
          :
          sending ?
            <>
              <Title>Sending...</Title>
              <TitleText>This might take a few seconds...</TitleText>
            </>
            :
            sendConfirmationSegment
        :
        <Spinner
          size="4em"
          $color="#44BBA4"
        />
      }
    </SendDialogContainer>
  </Dialog>;
};
