import React, {FunctionComponent} from "react";
import { defaultContract } from "../pricelist";

export interface ScenarioInput {};
export interface ScenarioPart {
  form : FunctionComponent;
  diagram : FunctionComponent;
  description? : string;
};

export interface Context<I extends ScenarioInput> {
  getState: () => I;
  handleChange: (changes:I) => void;
};

export abstract class Scenario extends React.Component {

  abstract formData: ScenarioInput;

  records : string[] = [];

  setRecords(recs:string[]) {
    this.records = recs;
  }
  getRecords() : string[] {
    return this.records;
  }
  handleChange = (changes:ScenarioInput) => {
    const newState = {...this.state, ...changes};
    this.state = newState; // Not clear why - but using setState() here instead of direct assignment does not work
    this.setRecords(this.generateRecords(this.state, defaultContract.costCenters[0].domains[0]));
  };

  context: Context<ScenarioInput> = {
    getState: () => this.state,
    handleChange : this.handleChange.bind(this)
  };

  abstract generateRecords: (formData:ScenarioInput, domain:string) => string[];

  abstract getName() : string;

  abstract getParts(): ScenarioPart[];

  render() {
    return (<>
      {this.getParts().map((part,i) => (
        <div key={i}>
          <div>
            {part.diagram({})}
          </div>
          <div>
            <p style={{width:"600px"}}>{part.description||""}</p>
          </div>
          <div style={{marginTop: "30px"}}>
            {part.form({})}
          </div>
        </div>
      ))}
    </>);
  };
}
