import { useCookies } from "react-cookie";
import useLocalStorage from "react-use-localstorage";
import { useQueryClient } from "react-query";

interface UseLogoutArguments {
  from?: string;
}

const useLogout = ({ from }: UseLogoutArguments) => {

  const client = useQueryClient();

  const cookies = useCookies(["authenticated"]);
  const removeCookie = cookies[2];
  const localStorage = useLocalStorage("credentials");
  const setCredentials = localStorage[1];

  const logout = () => {
    console.log("Logout got invoked from: ", from);
    client.cancelQueries();

    removeCookie("authenticated");
    setCredentials("");
  };

  return ({
    logout
  });
};

export default useLogout;
