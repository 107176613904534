import { lazy, Suspense } from "react";
import { Scenario, ScenarioPart, ScenarioInput, Context } from "../Scenario";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {RecordType} from "../records";
import { Spinner } from "../../../Spinner";

const Mermaid = lazy(() => import("../Mermaid"));

const flow1 = (cnt:Counters) => `graph LR;
SIPTrunk --${cnt.incomingTrunkCalls} incoming external call${cnt.incomingTrunkCalls!==1?"s":""}-->  SIPService  -->  MediaService;
WebRTC --> SIPService;
`;

const flow2 = (cnt:Counters) => `graph LR;
MediaService --${cnt.totalCalls} media service call${cnt.totalCalls!==1?"s":""}-->  SIPService  -->  WebRTC;
SIPService  --${cnt.outgoingTrunkCalls} outgoing external call${cnt.outgoingTrunkCalls!==1?"s":""}-->  SIPTrunk;
`;

interface Counters {
  totalCalls: number;
  incomingTrunkCalls: number;
  outgoingTrunkCalls: number;
  callLength: number;
  ivrLength: number;
}

const processCounters : (formData?:InputData) => Counters = (formData?:InputData) => {
  const totalCalls = formData?.numberOfCalls || 0;
  const incomingTrunkCalls = Math.round(totalCalls * (formData?.percentFromSipTrunk || 0) / 100);
  const outgoingTrunkCalls = Math.round(totalCalls * (formData?.percentToSipTrunk || 0) / 100);
  const callLength = (formData?.averageCallDuration || 0)/60;
  const ivrLength = (formData?.averageIvrDuration || 0)/60;
  return {
    totalCalls, incomingTrunkCalls, outgoingTrunkCalls, callLength, ivrLength
  };
};

interface InputData extends ScenarioInput {
  numberOfCalls?: number;
  averageCallDuration?: number;
  averageIvrDuration?: number;
  percentFromSipTrunk?: number;
  percentToSipTrunk?: number;
};

export class MediaService extends Scenario {

  constructor(props:{}) {
    super(props);
    this.state = this.formData;
  }

  formData: InputData = {
    numberOfCalls: 1,
    averageIvrDuration: 30,
    percentFromSipTrunk: 50,
    percentToSipTrunk: 50,
    averageCallDuration: 50
  };

  generateRecords = (formData?:InputData, domain?:string) => {
    const cnt = processCounters(formData);
    return [
      `${domain}, ${RecordType.VoipTerminating.value.name}, ${cnt.incomingTrunkCalls}, ${cnt.incomingTrunkCalls * cnt.callLength}`,
      `${domain}, ${RecordType.VoipOriginating.value.name}, ${cnt.outgoingTrunkCalls}, ${cnt.outgoingTrunkCalls * cnt.callLength}`,
      `${domain}, ${RecordType.IvrWithMediaOriginating.value.name}, ${cnt.totalCalls}, ${cnt.totalCalls * cnt.ivrLength}`
    ];
  };

  getName(): string {
    return "MediaService";
  }
  getParts(): ScenarioPart[] {
    const cnt = processCounters(this.context.getState());
    return [
      {
        form: inputs1(this.context),
        diagram: diagram("diagram1", flow1(cnt)),
        description: "Incoming call to IVR service from WebRTC client or external source, routed through SIP trunk."
      },
      {
        form: Inputs2(this.context),
        diagram: diagram("diagram2", flow2(cnt)),
        description: "Based on IVR selection an action call is initiated to a WebRTC destination or external target.`"
      }
    ];
  }
}

const inputs1 = (ctx: Context<InputData>) => () => {

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "30ch" },
        }}
        autoComplete="off"
      >
        <div>
          <TextField
            id="numberOfCalls"
            label="Number of calls"
            type="number"
            defaultValue={ctx.getState().numberOfCalls}
            InputProps={{inputProps: { min: 0 }}}
            onChange={(evt)=>ctx.handleChange({numberOfCalls: parseInt(evt.target.value)})}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div>
          <TextField
            id="averageIvrDuration"
            label="Average IVR duration (seconds)"
            type="number"
            defaultValue={ctx.getState().averageIvrDuration}
            InputProps={{inputProps: { min: 0 }}}
            onChange={(evt)=>ctx.handleChange({averageIvrDuration: parseInt(evt.target.value)})}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div>
          <TextField
            id="percentFromSipTrunk"
            label="Percent of calls originating from SIP trunk"
            type="number"
            defaultValue={ctx.getState().percentFromSipTrunk}
            InputProps={{inputProps: { min: 0, max: 100 }}}
            onChange={(evt)=>ctx.handleChange({percentFromSipTrunk: parseInt(evt.target.value)})}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </Box>
    </>
  );
};

const Inputs2 = (ctx:Context<InputData>) => () => {

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "30ch" },
        }}
        autoComplete="off"
      >
        <div>
          <TextField
            id="averageCallDuration"
            label="Average action call duration (seconds)"
            type="number"
            defaultValue={ctx.getState().averageCallDuration}
            InputProps={{inputProps: { min: 0 }}}
            onChange={(evt)=>ctx.handleChange({averageCallDuration: parseInt(evt.target.value)})}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div>
          <TextField
            id="percentToSipTrunk"
            label="Percent of calls going out over SIP trunk"
            type="number"
            defaultValue={ctx.getState().percentToSipTrunk}
            InputProps={{inputProps: { min: 0, max: 100 }}}
            onChange={(evt)=>ctx.handleChange({percentToSipTrunk: parseInt(evt.target.value)})}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </Box>
    </>
  );
};

const diagram = (id:string, flow:string) => () => {
  return (
    <>
      <Suspense fallback={<Spinner size="4em" $color={"primary"}/>}>
        <Mermaid id={id} chart={flow} />
      </Suspense>
    </>
  );
};
