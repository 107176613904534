import { IJsonEditor, IJsonEditorComponent } from "./jsoneditor";
import { PrimaryButton, SecondaryButton, SimpleDialog } from "../Inputs";
import { signedFetch, billingCredentials } from "../utils";
import useLocalStorage from "react-use-localstorage";
import { MutableRefObject, useRef, useState, useEffect } from "react";
import { JsonEditor } from "jsoneditor-react";
import Ajv from "ajv";
import brace from "brace";
import "brace/ext/searchbox";
import "brace/mode/json";
import "brace/theme/dawn";
import contractSchema from "../../schemas/contract.schema.json";
import { Spinner } from "../Spinner";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import { defaultContract } from "./pricelist";
import styled from "../../util/styled";
import { stages, defaultStage, BillingStageSelector } from "./billingStages";

const RowDiv = styled.div`
display:flex; gap: 25px;
flex-direction:row;
;
`;

const InvoicingContract = (props: { path: string }) => {

  const ajv = new Ajv({ allErrors: true, verbose: true });

  const [credentials] = useLocalStorage("credentials");
  const contractRef:MutableRefObject<IJsonEditor|null> = useRef(null);

  const [waiting, setWaiting] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");
  const [contracts, setContracts] = useState<string[]>([]);
  const [newContract, setNewContract] = useState<string>("");
  const [stage, setStage] = useState<string>(defaultStage);

  const setJsonEditorRef = (instance:IJsonEditorComponent) => {
    if (instance) {
      contractRef.current = instance.jsonEditor;
    } else {
      contractRef.current = null;
    }
  };

  useEffect(() => {
    setWaiting(true);
    signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api"})
    (`${stages[stage].url}contracts`, {})
      .then(response => {
        if(response.ok) {
          response.json().then(contracts => {
            setContracts(contracts);
            setWaiting(false);
          });
        } else {
          console.error(`Failed to fetch report. Result: ${JSON.stringify(response.body)} Headers:${JSON.stringify(response.headers)}`);
        }
        setWaiting(false);
      })
      .catch(err => {
        console.error(err);
      });
    setWaiting(false);
  }, [stage, credentials]);

  const fetchContract = (contract:string) => {
    setWaiting(true);
    signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api"})
    (`${stages[stage].url}contract?key=${contract}`, {})
      .then(response => {
        if(response.ok) {
          response.json().then(body => {
            contractRef.current?.set(body);
            setWaiting(false);
          });
        } else {
          console.error(`Failed to fetch contract. Result: ${JSON.stringify(response.body)} Headers:${JSON.stringify(response.headers)}`);
        }
        setWaiting(false);
      }).catch(err => {
        console.error(err);
      });
    setWaiting(false);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value as string);
    fetchContract(event.target.value as string);
  };

  const handleStageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStage(event.target.value as string);
    setSelected("");
    contractRef.current?.set({});
  };

  const handleDelete = async () => {
    setWaiting(true);
    signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api", method:"DELETE"})
    (`${stages[stage].url}contract?key=${selected}`, {})
      .then(response => {
        if(response.ok) {
          setContracts(contracts.filter(c => c !== selected));
          setSelected("");
          contractRef.current?.set({});
        } else {
          console.error(`Failed to delete contract. Result: ${JSON.stringify(response.body)} Headers:${JSON.stringify(response.headers)}`);
        }
        setWaiting(false);
      }).catch(err => {
        console.error(err);
        setWaiting(false);
      });
  };

  return (
    <>
      <h1>Contract</h1>
      <div>
        <div>
          <BillingStageSelector onChange={handleStageChange} state={stage}/>
        </div>
        <div>
          <FormControl>
            <InputLabel id="contract-select-label">Contracts</InputLabel>
            <Select
              style={{width:"200px"}}
              value={selected}
              onChange={handleChange}
              labelId="contract-select-label"
              id="contract-select"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {contracts.map((contract) => (
                <MenuItem key={contract} value={contract}>
                  {contract}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <FormControl>
          <RowDiv>
            <TextField
              fullWidth
              label="Add contract"
              placeholder="Contract key"
              value={newContract}
              onChange={event => setNewContract(event.target.value)}
            />
            <SecondaryButton onClick={(e)=> {
              setContracts([...contracts, newContract]);
              setSelected(newContract);
              contractRef.current?.set(defaultContract);
              setNewContract("");
            }}>Add</SecondaryButton>
          </RowDiv>
        </FormControl>
      </div>
      <div style={{marginTop:"20px"}}>
        <RowDiv>
          <PrimaryButton disabled={waiting} onClick={() => {
            const contract = contractRef.current?.get();
            if(contract) {
              setWaiting(true);
              signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api",
                body:JSON.stringify(contract), method:"POST"})
              (`${stages[stage].url}contract?key=${selected}`, {
                "Content-Type": "application/json"
              }).then(response => {
                if(response.ok) {
                  // Todo - inform about succesful save
                } else {
                  console.error(`Failed to fetch contract. Result: ${JSON.stringify(response.body)} Headers:${JSON.stringify(response.headers)}`);
                }
                setWaiting(false);
              }).catch(err => {
                console.error(err);
                setWaiting(false);
              });
            }}
          }
          >Save</PrimaryButton>
          <SimpleDialog {...{
            buttonLabel: "Delete",
            disableButton: !selected.length,
            heading: "Delete contract",
            waiting
          }} >
            <ConfirmDialogue {...{
              confirmFunc: handleDelete,
              classes: {},
              contentText: `Are you certain you want to delete the contract '${selected}'?`
            }} />
          </SimpleDialog>
        </RowDiv>
      </div>
      <div style={{height: "20px"}}/>
      <div style={{display: `${waiting ? "inherit" : "none"}`, height: "80px", marginTop: "25px", marginLeft:"80px"}}>
        <Spinner
          size="4em"
          $color={"primary"}
        />
      </div>

      <JsonEditor
        value={contractRef.current ? contractRef.current.get() : {}}
        ref={setJsonEditorRef}
        ace={brace}
        theme="ace/theme/dawn"
        ajv={ajv}
        schema={contractSchema}
        mode={"code"}
        htmlElementProps={{style: {height: 1000, width: 1000}}}
      />
    </>
  );
};


interface DialogueTypes {
  setOpen?: (bool: boolean) => void;
  confirmFunc: () => Promise<void>;
  domain?: string;
  contentText: string;
}

const StyledForm = styled.form`
  display: "flex";
  flex-direction: "column";
  margin: "auto";
  width: "fit-content";
`;

const ConfirmDialogue = ({ setOpen, confirmFunc, contentText }: DialogueTypes) => {
  return (
    <>
      <DialogContentText>
        {contentText}
      </DialogContentText>
      <StyledForm noValidate>
        <PrimaryButton variant="contained" color="primary" onClick={async () => {
          await confirmFunc();
          if (setOpen) setOpen(false);
        }}>
          Confirm
        </PrimaryButton>
      </StyledForm>
    </>
  );
};

export default InvoicingContract;
