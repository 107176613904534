import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme/styledComponentsTheme";

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider {...{ theme }}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
