type Key = symbol | number | string;
type Object = Record<Key, unknown>;

export type Ref<T> = { current?: T };

/**
 * Allows typing an array as a tuple without casting to const
 */
export const tuple = <T extends readonly unknown[] | []> (input: T) => input;

/**
 * Wrapper of Object.keys with improved type support
 */
type Keys<T extends Object> = Array<Extract<keyof T, string>>;
export const keys = <T extends Object> (target: T): Keys<T> => (
  Object.keys(target) as Extract<keyof T, string>[]
);

/**
 * Wrapper of Object.entries with improved type support
 */
type Entries<T extends Object> = Array<[Extract<keyof T, string>, T[Extract<keyof T, string>]]>;
export const entries = <T extends Object> (target: T): Entries<T> => {
  return keys(target).map(key => [key, target[key]]);
};

/**
 * Wrapper of Object.fromEntries with improved type support
 */
type UnknownEntries = Array<readonly [Key, unknown]> | Array<[Key, unknown]>;
type Constructed<T extends UnknownEntries> = Record<T[number][0], T[number][1]>;
export const fromEntires = <T extends Array<readonly [string, unknown]>> (entries: T): Constructed<T> => {
  return Object.fromEntries(entries) as Record<T[number][0], T[number][1]>;
};

// TODO: Consider if this should be moved into functions instead
export const mapObject = <T extends Record<Key, unknown>, U> (target: T, mapper: (value: Entries<T>[number][1], key: Entries<T>[number][0]) => U): Constructed<Array<[keyof T, U]>> => {
  return <Constructed<Array<[keyof T, U]>>> fromEntires(entries(target).map(([key, value]) => tuple([key, mapper(value, key)])));
};

/**
 * Casts a property inside an object to a new specified type.
 * To achieve this functionality this function needs to be called twice.
 * @example
 * const retypedObject = cast<NewType>()(targetObject, "targetKey");
 */
export const cast = <T = void>() => <U, K extends keyof U>(target: U, _: K) => target as T extends void ? U :
  Omit<U, K> & { [key in K]: T };
