import {devopsCredentials, signedFetch} from "./utils";
import {useEffect, useState} from "react";
import useLocalStorage from "react-use-localstorage";
import {useRoute, useLocation} from "wouter";
import styled from "../util/styled";

const Container = styled.div`
  body, html {width: 100%; height: 100%; margin: 0; padding: 0}
  .container {display: flex; width: 100%; height: 800px; flex-direction: column; overflow: hidden;}
`;

const getCreds = (credentials:string) => {
  return devopsCredentials(credentials)["api-access"];
};

const getContent = async (credentials:string, path:string) => {
  return (async () => await signedFetch({credentials: getCreds(credentials), region:"eu-west-1", service:"execute-api"})
  (`https://73qo6i3nh5.execute-api.eu-west-1.amazonaws.com/files/coverage/${path}`, {}))();
};
const CodeCoverage = () => {

  const [credentials] = useLocalStorage("credentials");
  const [waiting, setWaiting] = useState<boolean>(false);
  const [, match] = useRoute("/coverage/:id*");
  const [location] = useLocation();

  function replaceStaticResources(html:string) : string {
    const baseUrl = window.parent.location.origin + "/coverage/";
    const doc = new DOMParser().parseFromString(html, "text/html");

    for (let script of doc.getElementsByTagName("script")) {
      if(script.src) script.src = baseUrl + script.src.split("/").pop();
    }
    for (let link of doc.getElementsByTagName("link")) {
      if(link.href) link.href = baseUrl + link.href.split("/").pop();
    }
    return new XMLSerializer().serializeToString(doc);
  }

  useEffect(() => {

    const href = window.parent.location.origin + location;
    if(window.parent.location.href !== href) {
      window.parent.location.href = href;
    }
    setWaiting(true);
    getContent(credentials, match?.id || "")
      .then(response => {
        if(response.ok) {
          response.text().then(content => {
            const modifiedContent = replaceStaticResources(content);
            const iframe = document.getElementById("report-content") as HTMLIFrameElement;
            iframe?.contentWindow?.document.open();
            iframe?.contentWindow?.document.write(modifiedContent);
            iframe?.contentWindow?.document.close();
          });
        } else {
          console.error(`Failed to fetch code coverage report. Result: ${JSON.stringify(response.body)} Headers:${JSON.stringify(response.headers)}`);
        }
        setWaiting(false);
      })
      .catch(err => {
        console.error(err);
      });
    setWaiting(false);
  }, [location, credentials, match?.id]);

  return (
    <Container>
      <div style={{display: `${waiting ? "none" : "inherit"}`}}>
        <iframe id="report-content" title="report" src="about:blank" className="container"/>
      </div>
    </Container>
  );
};

export default CodeCoverage;
