import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import useLocalStorage from "react-use-localstorage";
import Select from "@material-ui/core/Select";
import { billingCredentials } from "../../components/utils";

export interface BillingStage {
    url: string;
    role: string;
};

export interface BillingStages {
  [key:string] : BillingStage;
};

export const stages : BillingStages = {
  "Prod": {url: "https://k4hvlj67k0.execute-api.eu-west-1.amazonaws.com/prod/", role: "billing-access"},
  "Dev": {url: "https://g9suolie6f.execute-api.eu-west-1.amazonaws.com/dev/", role: "devops-billing"}
};

export const defaultStage : string = "Prod";

type BillingStageSelectorProps = {
  state: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

export const BillingStageSelector = (props:BillingStageSelectorProps) => {

  const [credentials] = useLocalStorage("credentials");
  const creds = billingCredentials(credentials);

  return (
    <>
      <FormControl>
        <InputLabel id="stage-select-label">Stage</InputLabel>
        <Select
          style={{width:"200px"}}
          value={props.state}
          onChange={props.onChange}
          labelId="stage-select-label"
          id="stage-select"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          {Object.keys(stages).filter(stage =>
            Object.keys(creds).includes(stages[stage].role)).map((stage) => (
            <MenuItem key={stage} value={stage}>
              {stage}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
