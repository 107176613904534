import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { SimpleSelect } from "./Inputs";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  EnhancedTableToolbar,
  EnhancedTableHead,
  Order,
  HeadCell,
  useStyles,
  getComparator,
  stableSort,
  LoadingContainer
} from "./TableComponents";
import { handleTableCellClick, TableUserData, Data, parseRows, getLocaleTime } from "./tableUtils";

import { UseDataResponse } from "../customHooks/useData";

// TODO: split the tenant selector, put it inside the add dialogue, when user clicks "Add user to a domain"

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
`;


const headCells: HeadCell[] = [
  { id: "firstName", numeric: false, disablePadding: true, label: "First Name" },
  { id: "lastName", numeric: true, disablePadding: false, label: "Last Name" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  { id: "created", numeric: true, disablePadding: false, label: "Created in" },
  { id: "system", numeric: true, disablePadding: false, label: "System" },
];

interface TableProps {
  path?: string;
  searchString: string;
  setSelected: (value: TableUserData[]) => void;
  selected: TableUserData[];
  reload: boolean;
  deleteRequests: any[];
  users: UseDataResponse;
  action?: string;
}

export function EnhancedTable({ searchString, setSelected, selected, action, deleteRequests, users }: TableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("created");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const users = useData({ path: "/user?filter=unassigned", resource: "tenantApi", listen: reload });

  const rows = parseRows({ selected, users, searchString, returnMarked: action === "recover", deleteRequests });
  const sortedRows = stableSort(rows, getComparator(order, orderBy));


  useEffect(() => { setPage(0); }, [searchString]);

  useEffect(() => {
    if (sortedRows.length) {
      if (selected.length) {
        const lastSelected = selected[selected.length - 1];
        const indexOfLastSelected = sortedRows.findIndex((value) => value.id === lastSelected.id);
        const currentPage = Math.ceil((indexOfLastSelected + 1) / rowsPerPage);

        setPage(currentPage - 1);
      }
    } else {
      setPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, rowsPerPage]);


  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelecteds = rows.map((n: Data) => n.id);
      setSelected(rows);
      return;
    }
    setSelected([]);
  };


  const handleClick = (id: string) => {
    const newSelected = handleTableCellClick({ id, rows, selected });
    if (newSelected) {
      return setSelected(newSelected);
    }
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.find(s => s.id === id) ? true : false;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  type Page = {
    value: string;
    label: string;
  };

  const pages = (): Array<Page> => {
    const numOfPages = Math.ceil(rows.length / rowsPerPage);
    const p = [];
    for (let i = 1; i <= numOfPages; i++) {
      p.push({ value: i.toString(), label: i.toString() });
    }
    return p;
  };


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} tableTitle="Unassigned users" />
        <TableContainer>
          {users.loading ? <LoadingContainer><CircularProgress /></LoadingContainer> : <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={String(orderBy)}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sortedRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage + 1)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" className="firstName">
                        {row.firstName}
                      </TableCell>
                      <TableCell align="right" className="lastName">{row.lastName}</TableCell>
                      <TableCell align="right" className="email">{row.email}</TableCell>
                      <TableCell align="right" className="created">{getLocaleTime(row.created)}</TableCell>
                      <TableCell align="right" className="system">{row.system}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>}
        </TableContainer>
        <PaginationContainer>

          <SimpleSelect {...{
            options: pages(),
            selected: (page + 1).toString(),
            setSelected: (value) => setPage(Number(value) - 1),
            label: "Page"
          }}></SimpleSelect>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaginationContainer>
      </Paper>
    </div>
  );
}

export default EnhancedTable;
