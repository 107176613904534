import { useEffect } from "react";
import useLocalStorage from "use-local-storage";

import useLogout from "../customHooks/useLogout";
import useVisible from "../hooks/useVisible";
import { Credentials } from "../types";

export default function AutoLogout() {

  const { logout } = useLogout({from: "Auto logout"});
  const [isVisible] = useVisible();
  const [credentials] = useLocalStorage<Credentials[]>("credentials", "" as any);

  let expiration: number | undefined;
  if (credentials) {
    expiration = credentials
      .map(it => it.expiration)
      .reduce((prev, cur) => cur < prev ? cur : prev) - 5000;
  }

  useEffect(() => {
    const now = Date.now();

    if (expiration == null) {
      return;
    }

    if (now > expiration) {
      return logout();
    }

    const handle = setTimeout(logout, expiration - now);
    return () => clearTimeout(handle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiration, isVisible]);

  return <></>;
};
