import { environments } from "./declarations";

const accounts = {
  dev: "292435300779",
  stage: "902090632057", // Note: New accountId for new stage in eu-north-1
  prod: "344822328383"
};

export default environments({
  "blue-green": {
    account: accounts.dev,
    region: "eu-west-1"
  },

  "dev": {
    account: accounts.dev,
    region: "eu-west-1"
  },

  "test": {
    account: accounts.dev,
    region: "eu-west-1"
  },

  "stage": {
    account: accounts.stage,
    region: "eu-north-1"
  },

  "prod-eu-north-1": {
    account: accounts.prod,
    region: "eu-north-1"
  },

  "prod-us-east-1": {
    account: accounts.prod,
    region: "us-east-1"
  }
});
