import { FormControl } from "@material-ui/core";
import { Scenario } from "./Scenario";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { BS8521AlarmBridge } from "./scenarios/BS8521";
import { MediaService } from "./scenarios/MediaService";
import { Custom } from "./scenarios/Custom";
import MenuItem from "@material-ui/core/MenuItem";

type ScenarioSelectorProps = {
  selected: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

interface Scenarios {
  [key:string]: Scenario;
};

export const scenarios : Scenarios = {
  "BS8521AlarmBridge": new BS8521AlarmBridge({}),
  "MediaService": new MediaService({}),
  "Custom": new Custom({})
};

export const defaultScenario : string = Object.keys(scenarios)[0];

export const ScenarioSelector = (props: ScenarioSelectorProps) => {

  return (
    <>
      <div>
        <FormControl>
          <InputLabel id="scenario-select-label">Scenario</InputLabel>
          <Select
            style={{width:"200px"}}
            value={props.selected}
            onChange={props.onChange}
            labelId="scenario-select-label"
            id="scenario-select"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            {Object.keys(scenarios).map((scenario) => (
              <MenuItem key={scenario} value={scenario}>
                {scenario}
              </MenuItem>
            ))}
          </Select>

        </FormControl>
      </div>
      <div style={{marginTop:"40px"}}>
        {scenarios[props.selected].render()}
      </div>
    </>
  );
};
