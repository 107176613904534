import { FunctionComponent } from "react";
import { Scenario, ScenarioPart, ScenarioInput, Context } from "../Scenario";
import Box from "@mui/material/Box";
import {defaultRecords} from "../records";
import AceEditor from "react-ace";

interface InputData extends ScenarioInput {
  records?: string;
};
export class Custom extends Scenario {

  constructor(props:{}) {
    super(props);
    this.state = this.formData;
  }

  formData: InputData = {
    records: defaultRecords
  };

  generateRecords = (formData:InputData, domain:string) => formData.records ? formData.records.split("\n") : [];

  getName(): string {
    return "Custom";
  }
  getParts(): ScenarioPart[] {
    return [
      {
        form: inputs(this.context),
        diagram: ()=><></>
      }
    ];
  }
}

const inputs : (ctx: Context<InputData>) => FunctionComponent = (ctx:Context<InputData>) => () => {

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "15ch" },
        }}
        autoComplete="off"
      >
        <div>
          <h2>Usage records</h2>
          <AceEditor
            value={ctx.getState().records}
            mode="text"
            theme="dawn"
            height='800px'
            width='600px'
            onChange={(event: any) => ctx.handleChange({records: event as string})}
          />
        </div>
      </Box>
    </>
  );
};
