import React, { useEffect, useReducer, useCallback, useMemo } from "react";
import styled from "styled-components";

import { TenantData } from "./tableUtils";
import { getUrl, copyToClipboard } from "./utils";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClearIcon from "@material-ui/icons/Clear";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Snackbar from "@material-ui/core/Snackbar";
import ContentCopyIcon from "@material-ui/icons/FileCopyTwoTone";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";


import { Theme, makeStyles } from "@material-ui/core/styles";
import { Credentials, Action } from "../types";

import { PrimaryButton, SecondaryButton, SimpleDialog, SimpleSelect } from "./Inputs";
import DialogContentText from "@material-ui/core/DialogContentText";

import useData from "../customHooks/useData";

import TenantsTable from "./TenantsTable";

import CheckIcon from "@material-ui/icons/Check";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import useLocalStorage from "react-use-localstorage";

import { globalEnvironment } from "../config/globalEnvironment";
import { useLocation } from "wouter";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    // minWidth: 400,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  internalDns: {
    display: "flex",
    alignItems: "center",
  }
}));

export interface ContentProps {
  path: string;
}
interface LocalState {
  searchString: string;
  selected: TenantData[] | [];
  loading: boolean;
  reload: boolean;
  system: string;
  dnsRecord: string;
  isInternalDns: boolean;
  allowPut: boolean;
  unavailable: boolean;
  snack: {
    open: boolean;
    message: string;
    severity: Color;
    copy: string;
  };
}

const initialState: LocalState = {
  searchString: "",
  selected: [],
  loading: false,
  reload: false,
  system: "",
  dnsRecord: "",
  isInternalDns: false,
  allowPut: false,
  unavailable: false,
  snack: {
    open: false,
    message: "",
    severity: "success",
    copy: ""
  }
};

interface Actions {
  [key: string]: () => LocalState;
}

export const reducer = (state: LocalState, action: Action): LocalState => {
  const actions: Actions = {
    "SET_SNACK": () => {
      return {
        ...state,
        snack: action.value,
      };
    },
    "GET_TENANTS": () => {
      return {
        ...state,
        tenants: action.value,
      };
    },
    "SET_TENANT": () => {
      return {
        ...state,
        tenant: action.value,
      };
    },
    "SET_SYSTEM": () => {
      return {
        ...state,
        system: action.value,
        allowPut: false
      };
    },
    "SET_RECORD_TYPE": () => {
      return {
        ...state,
        dnsRecord: action.value,
        allowPut: false
      };
    },
    "SET_DNS_HOSTED_ZONE_INTERNAL_TYPE": () => {
      const { isInternal, system } = action.value;
      let newDnsRecord = state.dnsRecord;
      if (isInternal && state.dnsRecord) {
        newDnsRecord = state.dnsRecord.replace(`.${system}.`, `.internal-${system}.`);
      }
      if (!isInternal && state.dnsRecord) {
        newDnsRecord = state.dnsRecord.replace(`.internal-${system}.`, `.${system}.`);
      }
      return {
        ...state,
        isInternalDns: isInternal,
        dnsRecord: newDnsRecord,
        allowPut: false
      };
    },
    "ALLOW_PUT": () => {
      return {
        ...state,
        allowPut: action.value,
      };
    },
    "SET_UNAVAILABLE": () => {
      return {
        ...state,
        allowPut: !action.value,
        unavailable: action.value,
      };
    },
    "CHANGE_TENANT": () => {
      return {
        ...state,
        tenant: action.value,
        selected: [],
        allowPut: false
      };
    },
    "SET_SEARCH_STRING": () => {
      return {
        ...state,
        searchString: action.value,
        allowPut: false
      };
    },
    "SET_SELECTED": () => {
      return {
        ...state,
        selected: action.value,
        allowPut: false
      };
    },
    "BEGIN_LOADING": () => {
      return {
        ...state,
        loading: true
      };
    },
    "END_LOADING": () => {
      return {
        ...state,
        loading: false
      };
    },
    "END_REQUEST": () => {
      return {
        ...state,
        loading: false,
        reload: !state.reload,
        allowPut: false
      };
    },
    "RELOAD": () => {

      return {
        ...state,
        reload: !state.reload,

      };
    },
    "RESET": () => {
      return {
        ...state,
        selected: initialState.selected,
        searchString: initialState.searchString,
        system: ""

      };
    },
    "default": () => ({ ...state }),
  };

  return actions[action.type] ? actions[action.type]() : actions["default"]();
};

const DomainCreateBox = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 500px;
  background-color: ${props => props.theme.colors.grayBackground};
  padding: 30px 40px;
  box-shadow: 1px 2px 15px 0px rgb(0 0 0 / 20%);

`;

const DomainInputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
`;

const CheckBox = styled.div`
  position: relative;
  height: 27px;
  width: 27px;
`;


interface CheckIconProps {
  readonly $isActive: boolean;
}

const RestyledEmptyBox = styled(CheckBoxOutlineBlankIcon)`
  fill: lightgray;
`;


const RestyledCheck = styled(CheckIcon) <CheckIconProps>`
  fill: ${props => props.theme.colors.green};
  stroke: ${props => props.theme.colors.green};
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.5);
  /* transition: 1s all ease;
  stroke-dashoffset: ${props => props.$isActive ? "0px" : "1000px"}; */
  stroke-dasharray: 1000;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
  @keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

`;

const RestyledClose = styled(ClearIcon) <CheckIconProps>`
  fill: ${props => props.theme.colors.red};
  stroke: ${props => props.theme.colors.red};
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.5);
  /* transition: 1s all ease;
  stroke-dashoffset: ${props => props.$isActive ? "0px" : "1000px"}; */
  stroke-dasharray: 1000;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
  @keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
`;

const RestyledAdd = styled(GroupAddIcon) <CheckIconProps>`
  font-size: 8rem;
  margin: 0 auto;
  fill: ${props => props.$isActive ? props.theme.colors.green : props.theme.colors.main};
`;

const RestyledProgress = styled(CircularProgress)`
  margin: 0 auto;
`;

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CheckBoxContainer = styled.div`
  width: 27px;
`;

type TenantOptions = {
  role: string;
  resource: string;
}

interface DomainCreateInterface {
  $isActive: boolean;
  unavailable: boolean;
  loading: boolean;
  domainValue: string;
  onChange: (e: any) => void;
  setSelected: (value: string) => void;
  setDnsRecordType: (value: string) => void;
  setIsInternalDns: (value: boolean) => void;
  checkAvailability: () => Promise<void>;
  createTenant: () => Promise<void>;
  tenantOptions: { value: string, label: string }[];
  system: string;
  dnsRecord: string;
  isInternalDns: boolean;
  error?: boolean
};

const StyledForm = styled.form`
  display: "flex";
  flex-direction: "column";
  margin: "auto";
  width: "fit-content";
`;

interface DialogueTypes {
  setOpen?: (bool: boolean) => void;
  confirmFunc: () => Promise<void>;
  domain?: string;
  contentText: string;
}

const ConfirmDialogue = ({ setOpen, confirmFunc, contentText }: DialogueTypes) => {
  return (
    <>
      <DialogContentText>
        {contentText}
      </DialogContentText>
      <StyledForm noValidate>
        <PrimaryButton variant="contained" color="primary" onClick={async () => {
          await confirmFunc();
          if (setOpen) setOpen(false);
        }}>
          Confirm
        </PrimaryButton>
      </StyledForm>
    </>
  );
};

const ErrorContainer = styled.p`
  margin: 0 0 20px;
  font-weight: bold;
  color: red;
`;

const getDnsRecordTypes = (system: string, isInternalDns: boolean) => [
  {
    value: `sipserver.${isInternalDns ? "internal-" : ""}${system}.iotcomms.io`,
    label: "CNAME"
  },
  {
    value: `10 100 "S" "SIP+D2T" "" _sip._tcp.sipserver.${isInternalDns ? "internal-" : ""}${system}.iotcomms.io.`,
    label: "NAPTR"
  }
];

const DomainCreate = ({
  $isActive,
  domainValue,
  onChange,
  tenantOptions,
  system,
  dnsRecord,
  isInternalDns,
  setDnsRecordType,
  setIsInternalDns,
  setSelected,
  checkAvailability,
  createTenant,
  loading,
  unavailable,
  error
}: DomainCreateInterface) => {
  const classes = useStyles();

  return (<DomainCreateBox>
    <RestyledAdd {...{ $isActive: !!$isActive && !!system && !!domainValue }} />
    <DomainInputContainer>
      <Grid item xs>
        <TextField
          fullWidth
          label="Domain"
          placeholder="Type in the domain"
          value={domainValue}
          onChange={onChange}
          error={!!error}
          InputProps={{
            disableUnderline: true,
            className: classes.searchInput,
          }}
        />
      </Grid>
      <SimpleSelect {...{
        options: tenantOptions,
        selected: system,
        setSelected,
        label: "System"
      }}></SimpleSelect>
      <CheckBoxContainer>
        {($isActive || unavailable) && domainValue && system &&
          <CheckBox>
            <RestyledEmptyBox />
            {$isActive &&
              <RestyledCheck $isActive={$isActive} />
            }
            {unavailable &&
              <RestyledClose $isActive={unavailable} />}
          </CheckBox>
        }
      </CheckBoxContainer>
    </DomainInputContainer>
    <DomainInputContainer>
      <Tooltip placement="top" title={
        <>
          <p>Use <strong>CNAME</strong> for customers with clients, typically alarm operators (usually smaller customers).</p>
          <p>Use <strong>NAPTR</strong> for telecom customers, typically when connecting another operator.</p>
        </>
      }>
        <Grid item xs>
          <SimpleSelect {...{
            options: getDnsRecordTypes(system, isInternalDns),
            selected: dnsRecord,
            setSelected: setDnsRecordType,
            label: "DNS record type"
          }} />
        </Grid>
      </Tooltip>
      <Tooltip placement="top" title={
        <>
          <p>Checking this will create an internal domain in our internal (private) DNS zones.</p>
          <p>Example: <strong>my-domain.internal-test.iotcomms.io</strong></p>
          <p>Leaving it unchecked will create a public domain in our public DNS zone.</p>
          <p>Example: <strong>my-domain.test.iotcomms.io</strong></p>
        </>
      }>
        <Grid item xs className={classes.internalDns}>
          <Checkbox id ="isInternalCheckBox" checked={isInternalDns} onChange={() => setIsInternalDns(!isInternalDns)} />
          <label htmlFor="isInternalCheckBox">Internal Hosted Zone</label>
        </Grid>
      </Tooltip>
    </DomainInputContainer>
    {error && <ErrorContainer>Invalid domain name. The domain name should match this regex: /^[/.a-zA-Z0-9-*]+$/</ErrorContainer>}
    {loading ? <RestyledProgress /> : !$isActive ?
      <SecondaryButton onClick={checkAvailability} className={classes.addUser} disabled={!domainValue || !system}>
        Check availability
      </SecondaryButton>
      :
      <PrimaryButton onClick={createTenant} className={classes.addUser} disabled={!domainValue || !system || !dnsRecord}>
        Create domain
      </PrimaryButton>
    }
  </DomainCreateBox>);
};

export const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    display: flex;
    align-items: flex-end
  }
  svg {
    margin-left: 10px;
  }
`;

function TenantsManagement(props: ContentProps) {

  const [{ searchString, selected, loading, reload, system, dnsRecord, isInternalDns, allowPut, unavailable, snack }, localDispatch] = useReducer(reducer, initialState);
  const tenantsData = useData({ path: "/tenant", resource: "tenantApi", listen: reload });
  const deleteRequests = useData({ path: "/deleterequest", resource: "tenantApi", listen: reload });

  const [location] = useLocation();
  const { makeRequest } = tenantsData;

  const [credentials] = useLocalStorage("credentials");

  const tenantOptions = ({ role, resource }: TenantOptions) => {
    if (credentials) {
      const parsed = JSON.parse(credentials)
        .filter((cred: Credentials) => cred.roleName === role);
      const filtered = Object.keys(globalEnvironment).filter(key => {
        return key.startsWith(resource) &&
          globalEnvironment[key].roles?.includes(role) &&
          parsed.find((cred: Credentials) => cred.accountId === globalEnvironment[key].accountId);
      });
      return filtered;
    }
    return [];
  };

  const classes = useStyles();

  const handleRecoverTenant = async () => {
    const domain = selected[0].domain;
    const urls = [getUrl("tenantApi", selected[0].system)];
    localDispatch({ type: "BEGIN_LOADING" });

    try {

      const resp = await makeRequest({
        path: "/deleterequest/" + domain,
        urls,
        resource: "tenantApi",
        init: {
          method: "DELETE"
        },
        useJwt: true
      });
      localDispatch({ type: "END_REQUEST" });
      if (resp[0].success) {
        localDispatch({ type: "SET_SELECTED", value: [] });
        localDispatch({ type: "RELOAD" });
        localDispatch({ type: "SET_SNACK", value: { open: true, message: `The domain ${domain} is recovered`, severity: "success" } });
      } else {
        localDispatch({ type: "SET_SNACK", value: { open: true, message: `Failed to recover domain ${domain}`, severity: "error" } });

      }
    } catch (error) {
      localDispatch({ type: "SET_SNACK", value: { open: true, message: JSON.stringify(error), severity: "error" } });
    }
  };

  const handleDeleteTenant = async () => {
    const domain = selected[0].domain;
    const urls = [getUrl("tenantApi", selected[0].system)];
    localDispatch({ type: "BEGIN_LOADING" });


    const resp = await makeRequest({
      path: "/tenant/" + domain,
      urls,
      resource: "tenantApi",
      init: {
        method: "DELETE"
      },
      useJwt: true
    });
    localDispatch({ type: "END_REQUEST" });

    if (resp[0].success) {
      localDispatch({ type: "SET_SELECTED", value: [] });
      localDispatch({ type: "RELOAD" });
      localDispatch({ type: "SET_SNACK", value: { open: true, message: `The domain ${domain} will be deleted in 14 days`, severity: "success" } });
    } else {
      localDispatch({ type: "SET_SNACK", value: { open: true, message: `Failed to delete domain ${domain}`, severity: "error" } });

    }

  };

  const checkAvailability = async () => {

    localDispatch({ type: "BEGIN_LOADING" });

    if (!searchString || !system) return;
    const urls = [getUrl("tenantApi", system)];

    let response;
    try {
      response = await makeRequest({
        path: `/domainavailable/${searchString}${isInternalDns ? ".internal-" : ""}.iotcomms.io`,
        resource: "tenantApi",
        useJwt: true,
        urls
      });
    } catch (error) {
      console.error("Failed to check availability of domain", error);
      localDispatch({ type: "SET_SNACK", value: { open: true, message: `Failed to check availability of domain: ${searchString}.${isInternalDns ? "internal-" : ""}${system}.iotcomms.io. Please contact support.`, severity: "error" } });
      localDispatch({ type: "END_REQUEST" });
    }
    try {

      if (response && response.length) {
        const resp = response[0];
        if (!resp.success) {
          localDispatch({ type: "END_REQUEST" });
          return localDispatch({ type: "SET_SNACK", value: { open: true, message: `Failed to check availability of domain: ${searchString}.${isInternalDns ? "internal-" : ""}${system}.iotcomms.io. Please contact support.`, severity: "error" } });
        }

        localDispatch({ type: "END_REQUEST" });

        if (resp.domainAvailable) {
          localDispatch({ type: "SET_UNAVAILABLE", value: false });
          localDispatch({ type: "ALLOW_PUT", value: true });
          localDispatch({ type: "SET_SNACK", value: { open: true, message: `The domain ${searchString}.${isInternalDns ? "internal-" : ""}${system}.iotcomms.io is available`, severity: "success" } });
        } else {
          localDispatch({ type: "SET_UNAVAILABLE", value: true });
          localDispatch({ type: "SET_SNACK", value: { open: true, message: `The domain ${searchString}.${isInternalDns ? "internal-" : ""}${system}.iotcomms.io is not available`, severity: "error" } });
        }
      }
    } catch (error) {
      localDispatch({ type: "END_REQUEST" });
      console.error("Failed to parse response from domain availability request", error);
      localDispatch({ type: "SET_UNAVAILABLE", value: true });
      localDispatch({ type: "SET_SNACK", value: { open: true, message: JSON.stringify(error), severity: "error" } });
    }
  };

  const createTenant = async () => {

    localDispatch({ type: "BEGIN_LOADING" });

    if (!searchString || !system || !dnsRecord) return;

    const dnsTypes = getDnsRecordTypes(system, isInternalDns);
    let name;
    dnsTypes.forEach(type => {
      if (dnsRecord === type.value) {
        name = type.label;
      }
    });

    const urls = [getUrl("tenantApi", system)];
    try {
      const [tenant] = await makeRequest({
        path: "/tenant",
        resource: "tenantApi",
        urls,
        init: {
          method: "PUT",
          body: JSON.stringify({ domain: `${searchString}.iotcomms.io`, "dnsRecord": { name, value: dnsRecord, isInternal: isInternalDns } }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      });

      localDispatch({ type: "RELOAD" });
      localDispatch({ type: "SET_SNACK", value: { open: true, message: `Successfully created new tenant ${tenant.data.tenantData.Attributes.domain} and a ${name} DNS record`, severity: "success", copy: tenant.data.tenantData.Attributes.domain } });
    } catch (error) {
      console.error("Failed to assign users to tenant", error);
      localDispatch({ type: "SET_SNACK", value: { open: true, message: JSON.stringify(error), severity: "error" } });
    }
    localDispatch({ type: "END_REQUEST" });
    localDispatch({ type: "RESET" });
  };


  useEffect(() => {
    localDispatch({ type: "RESET" });
  }, [location]);

  const getSelected = useCallback(() => {
    if (selected.length) return selected[0];
  }, [selected]);

  const domain = useMemo(() => {
    const current = getSelected();
    if (current) return current.domain;
  }, [getSelected]);

  const error = useMemo(() => {
    if (!searchString) return false;
    const match = searchString.match(/^[/.a-zA-Z0-9-*]+$/);
    return !match;
  }, [searchString]);

  return (
    <>
      {(props.path === "overview" || props.path === "recover") &&
        <Paper className={classes.paper}>
          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                  <TextField
                    fullWidth
                    placeholder="Search by domain"
                    value={searchString}
                    onChange={(e) => {
                      localDispatch({ type: "SET_SEARCH_STRING", value: e.target.value });
                    }}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.searchInput,
                    }}
                  />
                </Grid>
                <Grid item>

                  <Tooltip title="Reload">
                    <IconButton onClick={() => localDispatch({ type: "RELOAD" })}>
                      <RefreshIcon className={classes.block} color="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {props.path === "overview" &&
                  <Grid item>

                    <SimpleDialog {...{
                      buttonLabel: "Delete",
                      disableButton: !selected.length,
                      heading: "Delete tenant",
                      loading
                    }} >
                      <ConfirmDialogue {...{
                        confirmFunc: handleDeleteTenant,
                        classes,
                        contentText: `Are you certain you want to mark ${domain} for deletion?`
                      }} />
                    </SimpleDialog>

                  </Grid>
                }
                {props.path === "recover" &&
                  <Grid item>

                    <SimpleDialog {...{
                      buttonLabel: "Recover",
                      disableButton: !selected.length,
                      heading: "Recover tenant",
                      loading
                    }} >
                      <ConfirmDialogue {...{
                        confirmFunc: handleRecoverTenant,
                        classes,
                        contentText: `Are you certain you want to recover ${domain}?`
                      }} />
                    </SimpleDialog>

                  </Grid>
                }
              </Grid>
            </Toolbar>
          </AppBar>
          <div className={classes.contentWrapper}>
            {props.path === "overview" &&
              <TenantsTable {...{
                searchString,
                setSelected: (value: TenantData[]) => localDispatch({ type: "SET_SELECTED", value }),
                selected,
                reload,
                tenants: tenantsData,
                handleDeleteTenant,
                handleRecoverTenant,
                deleteRequests: deleteRequests.data
              }} />
            }
            {props.path === "recover" &&
              <TenantsTable {...{
                searchString,
                setSelected: (value: TenantData[]) => localDispatch({ type: "SET_SELECTED", value }),
                selected,
                reload,
                tenants: tenantsData,
                handleDeleteTenant,
                handleRecoverTenant,
                deleteRequests: deleteRequests.data,
                action: "recover"
              }} />
            }


          </div>
        </Paper>}
      {props.path === "create" &&
        <>
          <DomainCreate
            {...{
              $isActive: allowPut,
              domainValue: searchString,
              onChange: (e: any) => localDispatch({ type: "SET_SEARCH_STRING", value: e.target.value }),
              tenantOptions: tenantOptions({ role: "api-access", resource: "tenantApi" }).map(op => ({ value: op.replace("tenantApi-", ""), label: op.replace("tenantApi-", "") })),
              setSelected: (value: string) => localDispatch({ type: "SET_SYSTEM", value }),
              unavailable,
              system,
              dnsRecord,
              isInternalDns,
              setDnsRecordType: (value: string) => localDispatch({ type: "SET_RECORD_TYPE", value }),
              setIsInternalDns: (isInternal: boolean) => localDispatch({ type: "SET_DNS_HOSTED_ZONE_INTERNAL_TYPE", value: { isInternal, system }}),
              loading,
              createTenant,
              checkAvailability,
              error
            }}
          ></DomainCreate>

        </>
      }
      <Snackbar open={snack.open} autoHideDuration={6000} onClose={() => localDispatch({ type: "SET_SNACK", value: initialState.snack })}>
        <StyledAlert onClose={() => localDispatch({ type: "SET_SNACK", value: initialState.snack })} severity={snack.severity}>
          {snack.message}
          {snack.copy &&
            <ContentCopyIcon onClick={() => copyToClipboard(snack.copy)} />
          }
        </StyledAlert>
      </Snackbar>
    </>
  );
}

export default TenantsManagement;
