// my-theme.ts
import { DefaultTheme } from "styled-components";
import typography from "./typography";
import shadows from "./shadows";
import borders from "./borders";

const iotcommsBlue = "#00659A";
const iotcommsGreen = "#44BBA4";



const theme: DefaultTheme = {
  borderRadius: "5px",

  colors: {
    main: iotcommsBlue,
    green: iotcommsGreen,
    red: "#E83A3A",
    darkBlue: "#2F4858",
    midBlue: "#305166",
    darkGray: "#393E41",
    lightGray: "#C3CEC9",
    grayBackground: "#F5F5F5",
    lightRed: "#C81E50",
    lightGreen: "#00C85A",
    lighterGreen: "rgba(0, 200, 90, 0.2)",
    lightYellow: "rgba(255, 214, 80, 0.2)"
  },
  // palette: {
  //   background: {
  //     default: "#FFFFFF",
  //     paper: "#FFFFFF"
  //   },
  //   primary: {
  //     light: "#63ccff",
  //     main: "#009be5",
  //     dark: "#006db3",
  //   },
  //   success: {
  //     main: "#00C85A"
  //   },
  //   text: {
  //     primary: "#172b4d",
  //     secondary: "#6b778c"
  //   }
  // },
  palette: {
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF"
    },
    primary: {
      contrastText: "#ffffff",
      main: "#00659A",
    },
    success: {
      main: "#00C85A"
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c"
    }
  },
  typography,
  shadows,
  borders
};

export { theme };

export default theme;
