import { defaultContract } from "../pricelist";

enum Scope {
  Domain, Customer
};

export interface ScopedRecord {
  scope: Scope;
  name: string;
};

export class RecordType {
  static readonly VoipOriginating = new RecordType("VoipOriginating", {scope: Scope.Domain, name: "VOIP  Minutes - originating"});
  static readonly VoipTerminating = new RecordType("VoipTerminating", {scope: Scope.Domain, name: "VOIP  Minutes - terminating"});
  static readonly VideoOriginating = new RecordType("VideoOriginating", {scope: Scope.Domain, name: "VIDEO  Minutes - originating"});
  static readonly VideoTerminating = new RecordType("VideoTerminating", {scope: Scope.Domain, name: "VIDEO  Minutes - terminating"});
  static readonly ScaipOriginating = new RecordType("ScaipOriginating", {scope: Scope.Domain, name: "SCAIP messages - originating"});
  static readonly ScaipTerminating = new RecordType("ScaipTerminating", {scope: Scope.Domain, name: "SCAIP messages - terminating"});
  static readonly IvrNoMediaOriginating = new RecordType("IvrNoMediaOriginating", {scope: Scope.Domain, name: "IVR no media - originating"});
  static readonly IvrNoMediaTerminating = new RecordType("IvrNoMediaTerminating", {scope: Scope.Domain, name: "IVR no media - terminating"});
  static readonly IvrWithMediaOriginating = new RecordType("IvrWithMediaOriginating", {scope: Scope.Domain, name: "IVR with media - originating"});
  static readonly IvrWithMediaTerminating = new RecordType("IvrWithMediaTerminating", {scope: Scope.Domain, name: "IVR with media - terminating"});
  static readonly Recorded = new RecordType("Recorded", {scope: Scope.Domain, name: "Recorded minutes"});
  static readonly MediaServiceRecorded = new RecordType("MediaServiceRecorded", {scope: Scope.Domain, name: "Media Service Call - recorded minutes"});
  static readonly AlarmOriginating = new RecordType("AlarmOriginating", {scope: Scope.Domain, name: "Analogue alarm - originating"});
  static readonly AlarmTerminating = new RecordType("AlarmTerminating", {scope: Scope.Domain, name: "Analogue alarm - terminating"});
  static readonly TranscodeOriginating = new RecordType("TranscodeOriginating", {scope: Scope.Domain, name: "Transcode Minutes - originating"});
  static readonly TranscodeTerminating = new RecordType("TranscodeTerminating", {scope: Scope.Domain, name: "Transcode Minutes - terminating"});
  static readonly Trunks = new RecordType("Trunks", {scope: Scope.Domain, name: "trunks"});
  static readonly Devices = new RecordType("Devices", {scope: Scope.Domain, name: "devices"});
  static readonly RealTimeMonitoring = new RecordType("RealTimeMonitoring", {scope: Scope.Domain, name: "RealTimeMonitoring"});
  static readonly VpnConnection = new RecordType("VpnConnection", {scope: Scope.Customer, name: "vpnConnection"});
  static readonly TransitGatewayAttachment = new RecordType("TransitGatewayAttachment", {scope: Scope.Customer, name: "transitGatewayAttachment"});
  static readonly DxCon50 = new RecordType("DxCon50", {scope: Scope.Customer, name: "dxcon50"});
  static readonly DxCon100 = new RecordType("DxCon100", {scope: Scope.Customer, name: "dxcon100"});
  static readonly DxCon200 = new RecordType("DxCon200", {scope: Scope.Customer, name: "dxcon200"});
  static readonly DxCon300 = new RecordType("DxCon300", {scope: Scope.Customer, name: "dxcon300"});
  static readonly DxCon400 = new RecordType("DxCon400", {scope: Scope.Customer, name: "dxcon400"});
  static readonly ResolverEndpoint = new RecordType("ResolverEndpoint", {scope: Scope.Customer, name: "resolverEndpoint"});

  private constructor(private readonly key:string, public readonly value:ScopedRecord) {}

  static getAll() : RecordType[] {
    return [
      RecordType.VoipOriginating,
      RecordType.VoipTerminating,
      RecordType.VideoOriginating,
      RecordType.VideoTerminating,
      RecordType.ScaipOriginating,
      RecordType.ScaipTerminating,
      RecordType.IvrNoMediaOriginating,
      RecordType.IvrNoMediaTerminating,
      RecordType.IvrWithMediaOriginating, 
      RecordType.IvrWithMediaTerminating,
      RecordType.Recorded,
      RecordType.MediaServiceRecorded,
      RecordType.AlarmOriginating,
      RecordType.AlarmTerminating,
      RecordType.TranscodeOriginating,
      RecordType.TranscodeTerminating,
      RecordType.Trunks,
      RecordType.Devices,
      RecordType.RealTimeMonitoring,
      RecordType.VpnConnection,
      RecordType.TransitGatewayAttachment,
      RecordType.DxCon50,
      RecordType.DxCon100,
      RecordType.DxCon200,
      RecordType.DxCon300,
      RecordType.DxCon400,
      RecordType.ResolverEndpoint
    ];
  }

  toString() {
    return this.key;
  }
};


export const defaultRecords: string = RecordType.getAll().map(
  type => `${type.value.scope === Scope.Domain ? defaultContract.costCenters[0].domains[0] : defaultContract.customerId}, ${type.value.name}, 0, 0.00`).join("\n");
