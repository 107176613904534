import CircularProgress from "@mui/material/CircularProgress";
import styled from "../util/styled";

export const SpinnerContainer = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

type SpinnerProps = { $color: string };
export const Spinner = styled(CircularProgress) <SpinnerProps>`
  color: ${props => props.$color};
`;
