import normalizeUrl from "normalize-url";
import { AwsClient } from "aws4fetch";

import { Resources, Combinations, Systems, Roles } from "../config/declarations";
import declaredResources from "../config/resources";
import declaredEnvironments from "../config/environments";
import declaredEndpoints from "../config/endpoints";

import { keys } from "./types";
import { between } from "./functions";
import { Credentials, findEndpointCredentials } from "./credentials";

type Methods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export type RequestOptions = {
  requiredRole?: Roles;
  method?: Methods;
  body?: string;
  headers?: Record<string, string>;
  retries?: number;
  throwOnErrorCode?: boolean;
  signal?: AbortSignal;
};

export type Response<T> = {
  status: number;
  system: Systems;
  body: T;
};

const requestSigV4 = async (path: string, system: Systems, credentials: Credentials, options: RequestOptions): Promise<Response<unknown>> => {

  const aws = new AwsClient({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: declaredEnvironments[system].region,
    service: "execute-api",
    retries: options.retries ?? 0
  });

  const request = aws.fetch(path, {
    method: options.method,
    body: options.body,
    headers: options.headers,
    signal: options.signal
  });

  const response = await request;
  const body = await response.json()
    .catch(() => undefined);

  return {
    status: response.status,
    system: system,
    body: body
  };
};

export type ResourceOrEndpoint = Resources | Combinations;

export type RequestReturnType<T extends ResourceOrEndpoint> =
  T extends Combinations ? Promise<Response<unknown>> :
  T extends Resources ? PromiseGroup<Response<unknown>> :
  never;

export default function request<T extends ResourceOrEndpoint>(
  endpoint: T,
  path: `/${string}`,
  options: RequestOptions = {}
): RequestReturnType<T> {

  // If resource is not a complete endpoint, recurse with all available endpoints for the given resource
  if (declaredResources.includes(<Resources> endpoint)) {
    const promises = keys(declaredEndpoints)
      .filter(it => it.startsWith(endpoint))
      .map(it => request(it, path, options));

    return <RequestReturnType<T>> makePromiseGroup(promises);
  }

  if (declaredEndpoints[<Combinations> endpoint] == null) {
    return <RequestReturnType<T>> Promise.reject(new Error(`The endpoint '${endpoint}' was not found!`));
  }

  let credentials: Credentials | undefined;
  const applicableCredentials = findEndpointCredentials(<Combinations> endpoint);

  if (options.requiredRole != null) {
    credentials = applicableCredentials.find(it => it.roleName === options.requiredRole);
  }
  else {
    credentials = applicableCredentials[0];
  }

  if (credentials == null) {
    return <RequestReturnType<T>> Promise.reject(new Error("No acceptable credentials were found to make the request!"));
  }

  const [, system] = <[Resources, Systems]> endpoint.split("/");
  const url = normalizeUrl(`${declaredEndpoints[<Combinations> endpoint]!.url}/${path}`);

  let response = requestSigV4(url, system, credentials, options);

  if (options.throwOnErrorCode ?? true) {
    response = response.then(it => {
      if (between(it.status, 400, 599)) {
        throw new RequestError(it);
      }

      return it;
    });
  }

  return <RequestReturnType<T>> response;
}

export const catchStatus = <T> (error: unknown, status: number, substitute: T) => {
  if (error instanceof RequestError && error.response.status === status) {
    return substitute;
  }
  else {
    throw error;
  }
};

export type PromiseGroup<T> = {
  all: () => Promise<T[]>;
  settled: () => Promise<PromiseSettledResult<T>[]>;
  array: () => Promise<T>[];
  map: <R> (fn: (promise: Promise<T>) => Promise<R>) => PromiseGroup<R>;
};

const makePromiseGroup = <T> (promises: Promise<T>[]): PromiseGroup<T> => ({
  all: () => Promise.all(promises),
  settled: () => Promise.allSettled(promises),
  array: () => promises,
  map: (fn) => (
    makePromiseGroup(promises.map(fn))
  )
});

export class RequestError extends Error {
  public readonly response: Response<unknown>;

  constructor(response: Response<unknown>) {
    super(`[${response.status}] ${JSON.stringify(response.body)}`);
    this.name = "RequestError";
    this.response = response;
  }
}
