import { useCallback, useEffect, useReducer } from "react";

import { AwsClient } from "aws4fetch";
import { Action } from "../types";

import { credentialsFinder, CredentialsWithEndpoints } from "./helpers";

import useLogout from "./useLogout";


interface DataState {
  data: any;
  loading: boolean;
  refresh: boolean;
  error?: {
    reason: string;
    error?: any;
  }
}

const initialState: DataState = {
  data: {},
  loading: true,
  refresh: false,
};

interface Actions {
  [key: string]: () => DataState;
}

const reducer = (state: DataState, action: Action): DataState => {
  const actions: Actions = {
    "LOAD_DATA": () => {
      return { ...state, data: action.value, loading: false };
    },
    "UNLOAD_DATA": () => {
      return { ...state, data: {} };
    },
    "LOADING": () => {
      return { ...state, loading: true };
    },
    "LOADED": () => {
      return { ...state, loading: false };
    },
    "REFRESH": () => {
      return { ...state, refresh: !state.refresh };
    },
    "ERROR": () => {
      return { ...state, error: action.error, loading: false };
    },
    "default": () => {
      return { ...state };
    }
  };
  return actions[action.type] ? actions[action.type]() : actions["default"]();
};

interface RequestOptions {
  method?: string,
  headers?: {
    [key: string]: string;
  },
  body?: string;
}
export interface RequestArgs {
  path?: string;
  init?: RequestOptions;
  resource?: string;
  urls?: string[];
  recursive?: boolean;
  useJwt?: boolean;
  logout?: () => void;
  lastEvaluatedKey?: any;
  getJwtRequest?: boolean;
  searchParams?: {
    [key: string]: any;
    systemSpecific?: {
      [key: string]: {
        [key: string]: any;
      };
    }
  }
}

export interface UseDataArgs extends RequestArgs {
  listen?: any;
}

export interface UseDataResponse {
  data: Array<any>;
  loading: boolean;
  setRefresh: () => void;
  unloadData: () => void;
  makeRequest: (args: any) => any;
  error?: {
    reason: string;
    error?: any;
  }
}

export const makeRequest = async ({ path, resource, urls, init, useJwt, logout, searchParams, getJwtRequest }: RequestArgs) => {
  if (!path || !resource) return;
  const cookies = document.cookie;
  if (!cookies.includes("authenticated=authenticated") && !getJwtRequest) {
    if (logout) {
      logout();
    } else {
      console.log("the localStorage.removeItem was invoked to remove credentials in the makeRequest function in the !authenticated scenario");
      localStorage.removeItem("credentials");
    }
    return;
  }

  const credentials = credentialsFinder({ resource, urls });

  // if no credentials logout
  if (!credentials) {

    if (logout) {
      logout();
    } else {
      console.log("the localStorage.removeItem was invoked to remove credentials in the makeRequest function in the !credentials scenario");
      localStorage.removeItem("credentials");
    }
    return;
  };

  let promises: Array<Promise<any>> = [];

  credentials.forEach(async (creds: CredentialsWithEndpoints) => {
    let { accessKeyId, secretAccessKey, sessionToken, endpoints, jwt, accountId } = creds;

    promises = [...promises, ...endpoints.map(async ({ endpoint, system }) => {
      let region = "eu-west-1";
      if (endpoint.region) region = endpoint.region;
      // Note: New stage is in the eu-north-1 region
      if (endpoint.url.indexOf("stage") > -1) {
        region = "eu-north-1";
      }
      let url = `${endpoint.url}${path}`;
      if (searchParams) {
        const paramsCopy = { ...searchParams };
        delete paramsCopy.systemSpecific;
        const queryStringParams = new URLSearchParams(paramsCopy);

        if (searchParams.systemSpecific) {
          const specifics = searchParams.systemSpecific[system];
          if (specifics) {
            Object.keys(specifics).forEach(sp => {
              queryStringParams.append(sp, specifics[sp].toString());
            });
          }
        }
        url = url + "?" + queryStringParams.toString();

      }
      try {

        if (useJwt) {
          if (!jwt || !jwt.length) return;
          const systemJWT = jwt.find(t => t.system === system);


          let options: RequestOptions = { headers: {} };
          const Authorization = "Bearer " + systemJWT?.token;
          if (!init) {
            options.headers = {
              Authorization
            };
          } else if (init?.headers) {
            init.headers.Authorization = Authorization;
            options = init;
          } else {
            init.headers = {
              Authorization
            };
            options = init;
          }

          return await fetch(url, options).then(data => data.json());
        }

        const aws = new AwsClient({ accessKeyId, secretAccessKey, sessionToken, region, service: "execute-api" });

        return await aws.fetch(url, init)
          .then(data => data.json())
          .then(data => {
            if (typeof data === "string") {
              return { data, system, roles: endpoint.roles, accountId };
            } else {
              return { ...data, system, roles: endpoint.roles, accountId };
            }
          });
      } catch (error) {
        console.error("Failed to fetch all the data", error);
        return ({ success: false, reason: "Failed to fetch all the data", error });
      }
    })];
  });
  const data = await Promise.all(promises).then(data => data.filter(e => !!e));
  return (data);
};
// to test the hook
// mock the responses from aws4fetch and from fetch with example response
// mock the cookies and localStorage to have a fixed credentials object
// this would allow testing the parsing of the results and the different behaviours based on the params

const useData = (args: UseDataArgs): UseDataResponse => {
  const { path, init, listen } = args;

  const { logout } = useLogout({ from: "request to path: " + path });

  const [{ data, loading, refresh, error }, dispatch] = useReducer(reducer, initialState);

  const fetchData = useCallback(async () => {

    try {
      const data = await makeRequest({ ...args, logout });

      dispatch({ type: "LOAD_DATA", value: data });

    } catch (error) {
      dispatch({ type: "ERROR", error: { reason: "The makeRequest function threw an error", error } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, path, listen, refresh]);



  useEffect(() => {
    fetchData();

  }, [fetchData]);


  return ({
    data,
    loading,
    setRefresh: useCallback(() => dispatch({ type: "REFRESH" }), []),
    unloadData: useCallback(() => dispatch({ type: "UNLOAD_DATA" }), []),
    makeRequest: (args: RequestArgs) => makeRequest({ logout, ...args }),
    error
  });
};

export default useData;


// const fetchData = useCallback(async () => {
//   if (!path || !resource) return;

//   const credentials = credentialsFinder({ resource, urls });
//   if (!authenticated || Object.keys(authenticated).length === 0) {
//     logout();
//     return;
//   }

//   if (!credentials) {
//     return;
//   }

//   let promises: Array<Promise<any>> = [];

//   credentials.forEach(async (creds: CredentialsWithEndpoints) => {
//     let { accessKeyId, secretAccessKey, sessionToken, endpoints } = creds;

//     promises = [...promises, ...endpoints.map(async ({ endpoint, system }) => {
//       const getData = async (lastEvaluatedKey?: string): Promise<any> => {

//         let url = `${endpoint.url}${path}`;
//         if (lastEvaluatedKey) {
//           const searchParams = new URLSearchParams({ lastEvaluatedKey }).toString();
//           url = url + "&" + searchParams;
//         }

//         try {
//           let region = "eu-west-1";
//           if (endpoint.region) region = endpoint.region;

//           const aws = new AwsClient({ accessKeyId, secretAccessKey, sessionToken, region, service: "execute-api" });

//           return await aws.fetch(`${url}`, init)
//             .then(data => data.json())
//             .then(data => ({ ...data, system }))
//             .then(data => {
//               if (data.lastEvaluatedKey) {
//                 return getData(JSON.stringify(data.lastEvaluatedKey));
//               } else {
//                 return data;
//               }
//             });
//         } catch (error) {
//           console.error("Failed to fetch all the data from endpoint: " + endpoint + " and system: " + system, error);
//           dispatch({ type: "ERROR", error });
//         }
//       };

//       return getData();
//     })];
//   });

//   const data = await Promise.all(promises).then(data => data.filter(e => !!e));


//   // Merge response objects
//   //TODO: if a success false appears, raise an error toast
//   // or actually just return an error to be handled where invoked
//   dispatch({ type: "LOAD_DATA", value: data });
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [init, path, listen, refresh]);
