import { IJsonEditor, IJsonEditorComponent } from "./jsoneditor";
import { PrimaryButton } from "../Inputs";
import { signedFetch, billingCredentials } from "../utils";
import useLocalStorage from "react-use-localstorage";
import { MutableRefObject, useRef, useState, useEffect } from "react";
import { JsonEditor } from "jsoneditor-react";
import Ajv from "ajv";
import brace from "brace";
import "brace/ext/searchbox";
import "brace/mode/json";
import "brace/theme/dawn";
import contractSchema from "../../schemas/contract.schema.json";
import { Spinner } from "../Spinner";
import { stages, defaultStage, BillingStageSelector } from "./billingStages";

const InvoicingPricelist = (props: { path: string }) => {

  const ajv = new Ajv({ allErrors: true, verbose: true });

  const [credentials] = useLocalStorage("credentials");
  const pricelistRef:MutableRefObject<IJsonEditor|null> = useRef(null);

  const [waiting, setWaiting] = useState<boolean>(false);
  const [stage, setStage] = useState<string>(defaultStage);

  const setJsonEditorRef = (instance:IJsonEditorComponent) => {
    if (instance) {
      pricelistRef.current = instance.jsonEditor;
    } else {
      pricelistRef.current = null;
    }
  };

  const handleStageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStage(event.target.value as string);
  };

  useEffect(() => {
    setWaiting(true);
    signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api"})
    (`${stages[stage].url}pricelist`, {})
      .then(response => {
        if(response.ok) {
          response.json().then(body => {
            pricelistRef.current?.set(body);
            setWaiting(false);
          });
        } else {
          console.error(`Failed to fetch pricelist. Result: ${JSON.stringify(response.body)} Headers:${JSON.stringify(response.headers)}`);
          setWaiting(false);
        }
      })
      .catch(err => {
        console.error(err);
        setWaiting(false);
      });
  }, [stage, credentials]);

  return (
    <>
      <h1>Pricelist</h1>
      <div>
        <BillingStageSelector onChange={handleStageChange} state={stage}/>
      </div>
      <div style={{marginTop: "25px"}}>
        <PrimaryButton disabled={waiting} onClick={() => {
          const contract = pricelistRef.current?.get();
          if(contract) {
            setWaiting(true);
            signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api",
              body:JSON.stringify(contract), method:"POST"})
            (`${stages[stage].url}pricelist`, {
              "Content-Type": "application/json"
            }).then(response => {
              if(response.ok) {
                // Todo - inform about succesful save
              } else {
                console.error(`Failed to update pricelist. Result: ${JSON.stringify(response.body)} Headers:${JSON.stringify(response.headers)}`);
              }
              setWaiting(false);
            }).catch(err => {
              console.error(err);
              setWaiting(false);
            });
          }}
        }
        >Save</PrimaryButton>
      </div>
      <div style={{height: "20px"}}/>
      <div style={{display: `${waiting ? "inherit" : "none"}`, height: "80px", marginTop: "25px", marginLeft:"80px"}}>
        <Spinner
          size="4em"
          $color={"primary"}
        />
      </div>

      <JsonEditor
        value={pricelistRef.current ? pricelistRef.current.get() : {}}
        ref={setJsonEditorRef}
        ace={brace}
        theme="ace/theme/dawn"
        ajv={ajv}
        schema={contractSchema}
        mode={"code"}
        htmlElementProps={{style: {height: 1000, width: 1000}}}
      />
    </>
  );
};

export default InvoicingPricelist;
