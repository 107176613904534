import React from "react";
import styled from "styled-components";
import { useLocation } from "wouter";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";



const lightColor = "rgba(255, 255, 255, 0.7)";

const StyledAppBar = styled(AppBar)`
  background-color: ${props => props.theme.colors.main};


`;


const useStyles = makeStyles((theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
}));

interface HeaderProps {
  onDrawerToggle: () => void;
  title: string;
}

type Route = {
  route: string;
  label: string;
}
interface Routes {
  [key: string]: Route[]
}

const routes: Routes = {
  users: [
    {
      route: "unassigned",
      label: "Unassigned Users"
    },
    {
      route: "assigned",
      label: "Assigned Users"
    }
  ],
  tenants: [
    {
      route: "create",
      label: "Create a Tenant"
    },
    {
      route: "overview",
      label: "Tenants Overview"
    },
    {
      route: "recover",
      label: "Recover Tenants"
    }
  ],
  "/": []
};

function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;
  const classes = useStyles();

  const [location, setLocation] = useLocation();

  const matchRoute = Object.keys(routes).find(route => location.includes(route));
  const route = matchRoute ? matchRoute : "/";

  const activeTab = () => {
    const subroute = routes[route].find(r => location.includes(r.route));
    return routes[route].indexOf(subroute ? subroute : routes[route][0]);
  };

  return (
    <React.Fragment>
      <StyledAppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </StyledAppBar>
      <StyledAppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
      </StyledAppBar>
      <StyledAppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Tabs value={activeTab()} textColor="inherit">
          {routes[route].map(({ route, label }: Route) => (
            <Tab key={label + route} textColor="inherit" label={label} onClick={() => setLocation(route)} />
          ))}
        </Tabs>
      </StyledAppBar>
    </React.Fragment>
  );
}

export default Header;
