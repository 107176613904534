import { JsonEditor } from "jsoneditor-react";
import { IJsonEditor, IJsonEditorComponent } from "./jsoneditor";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";
import brace from "brace";
import "brace/ext/searchbox";
import "brace/mode/json";
import "brace/theme/dawn";
import contractSchema from "../../schemas/contract.schema.json";
import styled from "../../util/styled";
import { PrimaryButton } from "../Inputs";
import { MutableRefObject, useRef, useState } from "react";
import useLocalStorage from "react-use-localstorage";
import { signedFetch, billingCredentials } from "../utils";
import { pricelist, defaultContract } from "./pricelist";
import { Switch, FormControl, FormGroup, FormControlLabel } from "@material-ui/core";
import { stages, defaultStage, BillingStageSelector } from "./billingStages";
import { ScenarioSelector, defaultScenario, scenarios } from "./simulation/SimulationScenario";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

const InvoicingSimulator = (props: { path: string }) => {

  const ajv = new Ajv({ allErrors: true, verbose: true });

  const [credentials] = useLocalStorage("credentials");

  const contractRef:MutableRefObject<IJsonEditor|null> = useRef(null);

  const [advancedMode, setAdvancedMode] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [stage, setStage] = useState<string>(defaultStage);
  const [scenario, setScenario] = useState<string>(defaultScenario);
  const [simulationResult, setSimulationResult] = useState<string[]>([]);

  const setJsonEditorRef = (instance:IJsonEditorComponent) => {
    if (instance) {
      contractRef.current = instance.jsonEditor;
    } else {
      contractRef.current = null;
    }
  };

  const Horizontal = styled.div`
  display:flex; gap: 25px;
  flex-direction:row;
  ;
`;

  const onAdvancedToggle = () => {
    setAdvancedMode(!advancedMode);
  };

  const handleStageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStage(event.target.value as string);
  };

  const handleScenarioChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setScenario(event.target.value as string);
  };

  const resultColumns: GridColDef[] = [
    {field: "customer", headerName: "Customer", width: 170},
    {field: "costcenter", headerName: "Cost Center", width: 150},
    {field: "domain", headerName: "Domain", width: 200},
    {field: "article", headerName: "Article", width: 220},
    {field: "count", headerName: "Count", width: 100},
    {field: "duration", headerName: "Duration", width: 100},
    {field: "cost", headerName: "Cost", width: 100}
  ];

  const resultRows: GridRowsProp =
    simulationResult.map(row => row.split(",")).map((cols:string[], i) =>
      ({
        id: i,
        key: i,
        customer: cols[0],
        costcenter: cols[1],
        domain: cols[2],
        article: cols[3],
        count: cols[4],
        duration: cols[5],
        cost: cols[6]
      }));

  return (
    <>
      <div style={{display: "none"}}>
        <iframe id="result-download" title="result-download"/>
      </div>
      <div style={{marginTop: "25px"}}>
        <BillingStageSelector onChange={handleStageChange} state={stage}/>
      </div>
      <div style={{marginTop: "25px"}}>
        <FormControl>
          <FormGroup>
            <FormControlLabel control={<Switch color={"primary"} onChange={onAdvancedToggle}/>} label="Customized prices" />
          </FormGroup>
        </FormControl>
      </div>
      <Horizontal>
        <div style={{marginTop: "25px"}}>
          <ScenarioSelector onChange={handleScenarioChange} selected={scenario}/>
        </div>
        <div style={{ display: `${advancedMode ? "default" : "none"}` }}>
          <h2>Simulated Contract</h2>
          <JsonEditor
            value={contractRef.current ? contractRef.current.get() : {...defaultContract, ...pricelist}}
            ref={setJsonEditorRef}
            ace={brace}
            theme="ace/theme/dawn"
            ajv={ajv}
            schema={contractSchema}
            mode={"code"}
            htmlElementProps={{style: {height: 800, width: 800}}}
          />
        </div>
      </Horizontal>
      <Horizontal>
        <div key="simulateButton" style={{marginTop: "25px"}}>
          <PrimaryButton disabled={waiting} onClick={() => {
            setWaiting(true);
            const records = scenarios[scenario].getRecords().join("\n");
            const contract = (advancedMode && contractRef.current) ? contractRef.current.get() : defaultContract;
            signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api",
              body:JSON.stringify({contract, records}), method:"POST"})
            (`${stages[stage].url}simulation`, {})
              .then(response => {
                if(response.ok) {
                  response.text().then(result => {
                    setSimulationResult(result.split("\n"));
                    setWaiting(false);
                  });
                }
                setWaiting(false);
              })
              .catch(err => {
                console.error(err);
                setWaiting(false);
              });
          }}>Simulate</PrimaryButton>
        </div>
        <div key="reportButton" style={{marginTop: "25px"}}>
          <PrimaryButton disabled={waiting} onClick={() => {
            setWaiting(true);
            const records = scenarios[scenario].getRecords().join("\n");
            const contract = (advancedMode && contractRef.current) ? contractRef.current.get() : defaultContract;
            signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api",
              body:JSON.stringify({contract, records, download: true}), method:"POST"})
            (`${stages[stage].url}simulation`, {})
              .then(response => {
                if(response.ok) {
                  response.text().then(url => {
                    const iframe = document.getElementById("result-download") as HTMLIFrameElement;
                    iframe.src = url;
                    setWaiting(false);
                  });
                }
                setWaiting(false);
              })
              .catch(err => {
                console.error(err);
                setWaiting(false);
              });
          }}
          >Generate simulated report</PrimaryButton>
        </div>
      </Horizontal>
      <div>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Simulated cost results
          </Typography>
          <div style={{backgroundColor: "azure", height: 600, width: 1100}}>
            <DataGrid
              columns={resultColumns}
              rows={resultRows}
              loading={waiting}
              pageSize={50}
            />
          </div>
        </Grid>
      </div>
    </>
  );
};

export default InvoicingSimulator;
