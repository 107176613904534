import { Roles, Systems, Combinations, Resources } from "../config/declarations";
import declaredEndpoints from "../config/endpoints";
import declaredEnvironments from "../config/environments";
import { tuple } from "./types";

export type Credentials = {
  accessKeyId: string;
  accountId: string;
  expiration: number;
  roleName: Roles;
  secretAccessKey: string;
  sessionToken: string;
  jwt: Array<{
    token: string;
    system: Systems;
  }>
};

const fetchCredentials = (): Credentials[] => {
  const raw = localStorage.getItem("credentials");

  if (raw) {
    return JSON.parse(raw);
  }
  else {
    return [];
  }
};

const splitEndpoint = (endpoint: Combinations) => {
  const [resource, system] = endpoint.split("/");
  return tuple([<Resources> resource, <Systems> system]);
};

export const findEndpointCredentials = (endpoint: Combinations) => {
  const credentials = fetchCredentials();
  const [, system] = splitEndpoint(endpoint);

  if (declaredEndpoints[endpoint] == null) {
    return [];
  }

  // FIXME: This will probably break if using the same role name in the same account but in different regions
  return credentials.filter(cred => (
    declaredEndpoints[endpoint]!.roles.includes(cred.roleName) &&
    declaredEnvironments[system].account === cred.accountId
  ));
};
