import React, { useEffect, useReducer, useState, useMemo } from "react";
import styled from "styled-components";

import { TableUserData, DeleteRequest, TenantRolesRelation } from "./tableUtils";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";

import useData, { makeRequest } from "../customHooks/useData";

import UnassignedUsersTable from "./UnassignedUsersTable";
import AssignedUsersTable from "./AssignedUsersTable";

import { SimpleDialog, PrimaryButton } from "./Inputs";
import DialogContentText from "@material-ui/core/DialogContentText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";

import { Action } from "../types";
import { globalEnvironment } from "../config/globalEnvironment";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";
import { useLocation } from "wouter";


/*
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
*/

interface ChipSelectTypes {
  selected: string[];
  options: string[];
  handleChange: (event: any) => void;
}

const StyledSelectContainer = styled(FormControl)`
  flex-grow: 1;
  * {
    flex-grow: 1;
  }
`;

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ChipSelect = ({ selected, handleChange, options }: ChipSelectTypes) =>
  <StyledSelectContainer>
    <InputLabel id="roles-select-label">Roles</InputLabel>
    <Select
      labelId="roles-select-label"
      id="roles-select"
      multiple
      value={selected}
      onChange={handleChange}
      input={<Input id="select-multiple-chip" />}
      renderValue={(selected) => (
        <div>
          {(selected as string[]).map((value) => (
            <Chip key={value} label={value} />
          ))}
        </div>
      )}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </StyledSelectContainer>;

const AutoCompleteContainer = styled(Autocomplete)`
  width: 300px;
 
`;

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      // maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "40px 16px",
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlDialogue: {
      margin: 0,
      minWidth: 120,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content",
    },
  });

export interface ContentProps extends WithStyles<typeof styles> {
  path: string;
}
interface LocalState {
  tenants: string[];
  searchString: string;
  tenant: string;
  selected: TableUserData[] | [],
  loading: boolean;
  reload: boolean;
  snack: {
    open: boolean;
    message: string;
    severity: Color;
  };
}

const initialState: LocalState = {
  tenants: [],
  searchString: "",
  tenant: "All",
  selected: [],
  loading: false,
  reload: false,
  snack: {
    open: false,
    message: "",
    severity: "success"
  }
};

interface Actions {
  [key: string]: () => LocalState;
}

export const reducer = (state: LocalState, action: Action): LocalState => {
  const actions: Actions = {
    "GET_TENANTS": () => {
      return {
        ...state,
        tenants: action.value,
      };
    },
    "SET_SNACK": () => {
      return {
        ...state,
        snack: action.value,
      };
    },
    "SET_TENANT": () => {
      return {
        ...state,
        tenant: action.value,
      };
    },
    "CHANGE_TENANT": () => {
      return {
        ...state,
        tenant: action.value,
        selected: []
      };
    },
    "SET_SEARCH_STRING": () => {
      return {
        ...state,
        searchString: action.value
      };
    },
    "SET_SELECTED": () => {
      const returnValue = {
        ...state,
        selected: action.value
      };
      return returnValue;
    },
    "BEGIN_LOADING": () => {
      return {
        ...state,
        loading: true
      };
    },
    "END_LOADING": () => {
      return {
        ...state,
        loading: false
      };
    },
    "END_REQUEST": () => {
      return {
        ...state,
        loading: false,
        reload: !state.reload,
      };
    },
    "RELOAD": () => {

      return {
        ...state,
        reload: !state.reload,

      };
    },
    "RESET": () => {
      return {
        ...state,
        selected: initialState.selected,
        searchString: initialState.searchString

      };
    },
    "default": () => ({ ...state }),
  };

  return actions[action.type] ? actions[action.type]() : actions["default"]();
};

type TenantsArray = string[];
type DataFetched = {
  success: boolean,
  tenants: TenantsArray
}


const UserDomainContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px 0;
  width: 700px;
  margin: 0 auto;
  font-size:1.1428571428571428rem;
  > *,  .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth {
    padding: 0;
    margin: 0;
  }
  p {
    padding-bottom: 4px;
  }

`;

const StyledFormControl = styled(FormControl)`
`;

interface DomainSelectorTypes extends WithStyles<typeof styles> {
  tenants?: string[];
  selected: TableUserData;
  users?: { user: string, roles: string[], email: string }[];
  pairValues: {
    [key: string]: TableUserData
  },
  setPairValues: (newValue: { [key: string]: TableUserData }) => void;
}

const UnassignDomainSelector = ({ users, selected, classes, pairValues, setPairValues }: DomainSelectorTypes) => {
  return (<>
    <UserDomainContainer>
      <p>
        {selected.email}
      </p>
      <StyledFormControl className={classes.formControlDialogue}>
        <AutoCompleteContainer
          disableClearable
          options={selected.tenants ? selected.tenants.map(s => typeof s === "string" ?
            s.replace("tenant_", "") :
            s.domain.replace("tenant_", ""))
            : []}
          id="tenant-selector"
          autoComplete
          onChange={(event, value) => {
            const newPairValues = { ...pairValues };
            newPairValues[selected.id] = { ...selected, tenant: String(value) };
            setPairValues(newPairValues);
          }}
          value={!pairValues[selected.id].tenant ? "Select a domain to unassign" : pairValues[selected.id].tenant}
          renderInput={(params) => <TextField {...params} label="Domain" margin="normal" />}
        />
      </StyledFormControl>
    </UserDomainContainer>
  </>);
};

interface initialPairValuesSignature {
  [key: string]: TableUserData;
}

interface DialogueTypes extends WithStyles<typeof styles> {
  setOpen?: (bool: boolean) => void;
  unassignUsersFromTenant: ({ pairValues }: { pairValues: initialPairValuesSignature }) => Promise<void>;
  localDispatch: (action: Action) => void;
  tenantsForSystem: (system: string) => string[];
  tenants: string[] | TenantRolesRelation[];
  selected: TableUserData[] | [];
  tenant: string;
  users: { user: string, roles: string[], email: string }[];

}

const UnassignDialogue = ({ setOpen, unassignUsersFromTenant, classes, selected, users }: DialogueTypes) => {

  const initialPairValues = () => {
    const initial: initialPairValuesSignature = {};
    selected.forEach(s => {
      initial[s.id] = s;
    });
    return initial;
  };

  const [pairValues, setPairValues] = useState(initialPairValues());

  const disableButton = () => Object.keys(pairValues).reduce((prev, curr) => !pairValues[curr].tenant ? true : prev, false);

  return (
    <>
      <DialogContentText>
        Are you certain you want to unassign the selected user(s)?
      </DialogContentText>
      {/* <FormControl className={classes.formControl}>
        <AutoCompleteContainer
          disableClearable
          options={tenants.map(t => (t.replace("tenant_", "")))}
          id="tenant-selector"
          autoComplete
          onChange={(event, value) => localDispatch({ type: "SET_TENANT", value: "tenant_" + value })}
          value={tenant === "All" ? "Select a domain to unassign" : tenant.replace("tenant_", "")}
          renderInput={(params) => <TextField {...params} label="Domain" margin="normal" />}
        />
      </FormControl> */}
      {selected.map(user => <UnassignDomainSelector {...{ selected: user, users, classes, pairValues, setPairValues }}></UnassignDomainSelector>)}
      <form className={classes.form} noValidate>
        <PrimaryButton variant="contained" color="primary" disabled={disableButton()} onClick={async () => {
          await unassignUsersFromTenant({ pairValues });
          if (setOpen) setOpen(false);
        }} className={classes.addUser}>
          Confirm
        </PrimaryButton>
      </form>
    </>
  );
};


interface UnassignDialogueTypes extends WithStyles<typeof styles> {
  setOpen?: (bool: boolean) => void;
  tenantsForSystem: (system: string) => string[];
  assignUsersToTenant: (users: { user: string; roles: string[]; }[], tenant: string) => Promise<void>;
  tenants: string[];
  selected: TableUserData[] | [];
  users: { user: string, roles: string[], email: string }[];

}


const UserBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  /* justify-content: space-between; */
  width: 500px;
  align-items: center;
  p {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    text-overflow: ellipsis;
  }
`;

/*
const RestyledSimpleDialog = styled(SimpleDialog)`
  .dialog-component {
    max-width: auto;
  }
`;
*/

const roles = [
  "admin",
  "billing_administrator",
  "alarm_user",
  "hybrid_admin"
];

const AssignDialogue = ({ setOpen, tenants, classes, selected, assignUsersToTenant, tenantsForSystem, users }: UnassignDialogueTypes) => {
  const [usersArray, setUsersArray] = useState(users);
  const [tenant, setTenant] = useState("");

  const allowPut = useMemo(() => {

    if (!tenant || tenant === "All") return false;
    if (usersArray.length) {
      return usersArray.reduce((prev, curr) => !curr.roles.length ? false : prev, true);
    }
    return false;
  }, [usersArray, tenant]);

  const handleChange = (e: any, index: number) => {
    const selectedRoles = e.target.value;
    const newArray = [...usersArray];
    newArray[index].roles = selectedRoles;
    setUsersArray(newArray);
  };

  return (
    <>
      <DialogContentText>
        Select tenant to which user(s) should be assigned
      </DialogContentText>
      <form className={classes.form} noValidate>
        {usersArray.map((u, i) =>
          <UserBox>
            <DialogContentText>
              {u.email}
            </DialogContentText>

            <ChipSelect {...{
              selected: usersArray[i].roles,
              options: roles,
              handleChange: (e: any) => handleChange(e, i)
            }} />
          </UserBox>
        )}
        <FormControl className={classes.formControl}>
          <AutoCompleteContainer
            options={selected.length ? tenantsForSystem(selected[0].system).map(t => (t.replace("tenant_", ""))) : tenants.map(t => (t.replace("tenant_", "")))}
            id="tenant-selector"
            autoComplete
            disableClearable
            onChange={(event, value) => value ? setTenant("tenant_" + value) : setTenant("All")}
            value={tenant === "All" ? "Select a domain" : tenant.replace("tenant_", "")}
            renderInput={(params) => <TextField {...params} label="Domain" margin="normal" />}
          />
        </FormControl>
        <PrimaryButton
          variant="contained"
          color="primary"
          onClick={async () => {
            await assignUsersToTenant(usersArray, tenant.replace("tenant_", ""));
            if (setOpen) {
              setOpen(false);
            }
          }}
          className={classes.addUser}
          disabled={!allowPut}
        >
          Confirm
        </PrimaryButton>
      </form>
    </>
  );
};

function UsersManagement(props: ContentProps) {

  const [{ tenants, tenant, searchString, selected, loading, reload, snack }, localDispatch] = useReducer(reducer, initialState);
  const tenantsData = useData({ path: "/tenant", resource: "tenantApi", listen: reload });
  const deleteRequests = useData({ path: "/deleterequest", resource: "tenantApi", listen: reload });
  const [location] = useLocation();

  // const usersDeleteRequests = useMemo(() => {
  //   const wholeResponse = { ...deleteRequests };
  //   const data = wholeResponse.data;
  //   if (data && data.length) {
  //     wholeResponse.data = data.map(re => {
  //       const filteredItems = re.data.Items.filter((item: { sk: string, pk: string, timeToLive: number }) => item.pk.startsWith("user_"));
  //       return ({ ...re, users: filteredItems });
  //     });
  //   }
  //   return wholeResponse;
  // }, [deleteRequests]);


  const assignedUsers = useData({
    path: "/user/profile",
    resource: "tenantApi",
    listen: reload,
    searchParams: {
      assigned: true
    }
  });
  const unassignedUsers = useData({ path: "/user?filter=unassigned", resource: "tenantApi", listen: reload });


  const filteredTenants = useMemo(() => {
    if (deleteRequests.data && deleteRequests.data.length && tenantsData.data && tenantsData.data.length) {
      return tenantsData.data.map((ur: { tenants: string[], success: boolean, system: string }) => {
        const systRequests = deleteRequests.data.find(dr => dr.system === ur.system);
        const filteredUsers = ur.tenants.filter(u => {
          const marked = systRequests.data.Items.find((el: DeleteRequest) => el.pk === u);
          return !marked;
        });
        return ({ ...ur, tenants: filteredUsers });
      });
    } else {
      return [];
    }
  }, [deleteRequests.data, tenantsData.data]);

  const assignUsersToTenant = async (users: { user: string, roles: string[] }[], tenant: string) => {

    localDispatch({ type: "BEGIN_LOADING" });

    const currSystem = selected[0].system;
    const globalKeyName = Object.keys(globalEnvironment).find(v => v.startsWith("tenantApi") && v.endsWith(currSystem));
    if (!globalKeyName) return;
    const globalKey = globalEnvironment[globalKeyName];

    const urls = [globalKey.url];
    try {

      await makeRequest({
        path: `/tenant/${tenant.replace("tenant_", "")}/user`,
        resource: "tenantApi",
        urls,
        init: {
          method: "PUT",
          body: JSON.stringify({ users }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      });
      localDispatch({ type: "SET_SNACK", value: { open: true, message: "The users were successfully assigned to the tenants", severity: "success" } });
      localDispatch({ type: "RELOAD" });
      localDispatch({ type: "RESET" });


    } catch (error) {
      console.error("Failed to assign users to tenant", error);
      localDispatch({ type: "SET_SNACK", value: { open: true, message: JSON.stringify(error), severity: "error" } });

    }
    localDispatch({ type: "END_REQUEST" });
  };

  const unassignUsersFromTenant = async ({ pairValues }: { pairValues: initialPairValuesSignature }) => {

    localDispatch({ type: "BEGIN_LOADING" });


    const operations: { users: string[], tenant: string, system: string }[] = [];

    Object.keys(pairValues).forEach(id => {
      const existingOp = operations.find(op => op.tenant === pairValues[id].tenant);

      if (existingOp) {
        existingOp.users.push(id);
      } else {
        operations.push({ users: [id], tenant: String(pairValues[id].tenant), system: pairValues[id].system });
      }
    });

    if (operations.length) {

      const promises = operations.map(op => {
        const globalKeyName = Object.keys(globalEnvironment).find(v => v.startsWith("tenantApi") && v.endsWith(op.system));
        if (!globalKeyName) return undefined;
        const globalKey = globalEnvironment[globalKeyName];

        const urls = [globalKey.url];

        return makeRequest({
          path: `/tenant/${op.tenant}/user?users=${JSON.stringify(op.users)}`,
          resource: "tenantApi",
          urls,
          init: {
            method: "DELETE"
          }
        });
        // TODO: set refresh for users data

      });

      try {
        await Promise.all(promises);
        localDispatch({ type: "RELOAD" });
        localDispatch({ type: "RESET" });
        localDispatch({ type: "SET_SNACK", value: { open: true, message: "The users were successfully unassigned from the tenants", severity: "success" } });
      } catch (error) {
        console.error("Failed to unassign users", error);
        localDispatch({ type: "SET_SNACK", value: { open: true, message: JSON.stringify(error), severity: "error" } });
      }
    }

    // const currSystem = selected[0].system;
    // const globalKeyName = Object.keys(globalEnvironment).find(v => v.startsWith("tenantApi") && v.endsWith(currSystem));
    // if (!globalKeyName) return;
    // const globalKey = globalEnvironment[globalKeyName];

    // const urls = [globalKey.url];
    // const users = selected.map(s => ({ user: s.id, role: "admin" }));
    // try {

    //   await makeRequest({
    //     path: `/tenant/${tenant.replace("tenant_", "")}/user?users=${JSON.stringify(users.map(u => u.user))}`,
    //     resource: "tenantApi",
    //     urls,
    //     init: {
    //       method: "DELETE"
    //     }
    //   });
    //   // TODO: set refresh for users data
    //   localDispatch({ type: "RELOAD" });
    //   localDispatch({ type: "RESET" });
    //   localDispatch({ type: "SET_SNACK", value: { open: true, message: "The users were successfully unassigned from the tenants", severity: "success" } });

    // } catch (error) {
    //   console.error("Failed to unassign users", error);
    //   localDispatch({ type: "SET_SNACK", value: { open: true, message: JSON.stringify(error), severity: "error" } });

    // }
    localDispatch({ type: "END_REQUEST" });
  };

  const { classes } = props;

  useEffect(() => {
    if (filteredTenants.length) {
      const mergedData: { tenants: TenantsArray } = { tenants: ["All"] };
      filteredTenants.forEach((data: DataFetched) => {
        if (data.success) {
          mergedData.tenants = [...mergedData.tenants, ...data.tenants];
        }
      });
      localDispatch({ type: "GET_TENANTS", value: mergedData.tenants });
    }

  }, [localDispatch, filteredTenants]);

  useEffect(() => {
    localDispatch({ type: "RESET" });
  }, [location]);


  const tenantsForSystem = (system: string): string[] => {
    if (!selected.length) return [];
    const found = filteredTenants.find(ar => ar.system === system);
    if (found) {

      return found.tenants;
    } else {
      return [];
    }
  };

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            {props.path === "assigned" &&
              <AutoCompleteContainer
                options={tenants.map(t => (t.replace("tenant_", "")))}
                id="domain-selector"
                autoComplete
                onChange={(event, value) => {
                  if (value === null) {
                    return localDispatch({ type: "CHANGE_TENANT", value: "All" });
                  }
                  localDispatch({ type: "CHANGE_TENANT", value: "tenant_" + value });
                }}
                value={tenant.replace("tenant_", "")}
                renderInput={(params) => <TextField {...params} label="Domain" margin="normal" />}
              />
            }
            <Grid item>
              <SearchIcon className={classes.block} color="inherit" />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search by name, email address, or user UID"
                value={searchString}
                onChange={(e) => {
                  localDispatch({ type: "SET_SEARCH_STRING", value: e.target.value });
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid>
            {props.path === "assigned" &&

              <Grid item>

                {selected[0] && selected[0].tenants &&
                  <SimpleDialog {...{
                    buttonLabel: "Unassign",
                    disableButton: !selected.length,
                    heading: "Unassign users",
                    loading,
                    width: "md"
                  }} >
                    <UnassignDialogue {...{
                      unassignUsersFromTenant,
                      selected,
                      tenantsForSystem,
                      localDispatch,
                      classes,
                      tenants: selected[0].tenants,
                      tenant,
                      users: selected.map(s => ({ user: s.id, roles: [], email: s.email }))
                    }} />
                  </SimpleDialog>
                }

              </Grid>
            }

            <Grid item>
              {props.path === "recover" &&
                <SimpleDialog {...{
                  buttonLabel: "Recover user",
                  disableButton: !selected.length,
                  heading: "Recover user",
                  loading
                }} >
                  <AssignDialogue {...{
                    assignUsersToTenant,
                    selected,
                    tenantsForSystem,
                    localDispatch,
                    classes,
                    tenants,
                    tenant,
                    users: selected.map(s => ({ user: s.id, roles: [], email: s.email }))
                  }} />

                </SimpleDialog>
              }
              {props.path !== "recover" &&
                <SimpleDialog {...{
                  buttonLabel: "Assign to tenant",
                  disableButton: !selected.length,
                  heading: "Assign users",
                  loading
                }} >
                  <AssignDialogue {...{
                    assignUsersToTenant,
                    selected,
                    tenantsForSystem,
                    localDispatch,
                    classes,
                    tenants,
                    tenant,
                    users: selected.map(s => ({ user: s.id, roles: [], email: s.email }))
                  }} />

                </SimpleDialog>
              }

              <Tooltip title="Reload">
                <IconButton onClick={() => localDispatch({ type: "RELOAD" })}>
                  <RefreshIcon className={classes.block} color="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {/* <Typography color="textSecondary" align="center"> */}
        {props.path === "assigned" &&
          <AssignedUsersTable {...{
            searchString,
            setSelected: (value: TableUserData[]) => localDispatch({ type: "SET_SELECTED", value }),
            selected,
            reload,
            tenant,
            deleteRequests: deleteRequests.data,
            users: assignedUsers,
            setSnack: (message: string, severity: string) => {
              localDispatch({ type: "SET_SNACK", value: initialState.snack });
              localDispatch({ type: "SET_SNACK", value: { open: true, message, severity } });
            }

          }} />
        }
        {props.path === "unassigned" &&
          <UnassignedUsersTable {...{
            searchString,
            setSelected: (value: TableUserData[]) => localDispatch({ type: "SET_SELECTED", value }),
            selected,
            reload,
            deleteRequests: deleteRequests.data,
            users: unassignedUsers
          }} />
        }
        {/* {props.path === "recover" &&
          <RecoverUsersTable {...{
            searchString,
            setSelected: (value: TableUserData[]) => localDispatch({ type: "SET_SELECTED", value }),
            selected,
            reload,
            deleteRequests: deleteRequests.data,
            assignedUsers: assignedUsers,
            unassignedUsers: unassignedUsers,
            action: "recover"
          }} />
        } */}
        {/* </Typography> */}
      </div>
      <Snackbar open={snack.open} autoHideDuration={6000} onClose={() => localDispatch({ type: "SET_SNACK", value: initialState.snack })}>
        <Alert onClose={() => localDispatch({ type: "SET_SNACK", value: initialState.snack })} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default withStyles(styles)(UsersManagement);
