import { FunctionComponent, lazy, Suspense } from "react";
import { Scenario, ScenarioPart, ScenarioInput, Context } from "../Scenario";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {RecordType} from "../records";
import { Spinner } from "../../../Spinner";
const Mermaid = lazy(() => import("../Mermaid"));

const flow = (data:InputData) => `graph LR;
In--${data.numberOfCalls} SCAIP message${data.numberOfCalls!==1?"s":""}-->SIP-->MediaService--${data.numberOfCalls} voice call${data.numberOfCalls!==1?"s":""}-->Out;
`;
interface InputData extends ScenarioInput {
  numberOfCalls?: number;
  averageCallDuration?: number;
}
export class BS8521AlarmBridge extends Scenario {

  constructor(props:{}) {
    super(props);
    this.state = this.formData;
  }

  formData: InputData = {
    numberOfCalls: 1,
    averageCallDuration: 50
  };

  generateRecords = (formData:InputData, domain:string) => [
    `${domain}, ${RecordType.VoipOriginating.value.name}, ${formData.numberOfCalls}, ${(formData.numberOfCalls || 0) * (formData.averageCallDuration || 0)/60}`,
    `${domain}, ${RecordType.ScaipOriginating.value.name}, ${formData.numberOfCalls},`
  ];

  getName(): string {
    return "BS8521AlarmBridge";
  }
  getParts(): ScenarioPart[] {
    return [
      {
        form: inputs(this.context),
        diagram: diagram(this.context),
        description: "Incoming SCAIP message triggering an analogue alarm call, with or without voice."
      }
    ];
  }
}

const inputs : (ctx: Context<InputData>) => FunctionComponent = (ctx:Context<InputData>) => () => {
  
  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "20ch" },
        }}
        autoComplete="off"
      >
        <div>
          <TextField
            id="numberOfCalls"
            label="Number of calls"
            type="number"
            defaultValue={ctx.getState().numberOfCalls}
            InputProps={{inputProps: { min: 0 }}}
            onChange={(evt)=>ctx.handleChange({numberOfCalls: parseInt(evt.target.value)})}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div>
          <TextField
            id="averageCallDuration"
            label="Average call duration (seconds)"
            type="number"
            InputProps={{inputProps: { min: 0 }}}
            defaultValue={ctx.getState().averageCallDuration}
            onChange={(evt)=>ctx.handleChange({averageCallDuration: parseInt(evt.target.value)})}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </Box>
    </>
  );
};

const diagram :(ctx: Context<InputData>) =>  FunctionComponent = (ctx:Context<InputData>) => () => {
  return (
    <>
      <Suspense fallback={<Spinner size="4em" $color={"primary"}/>}>
        <Mermaid id="diagram" chart={flow(ctx.getState())} />
      </Suspense>
    </>
  );
};
