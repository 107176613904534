import React, { ReactElement } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import styled from "styled-components";
import { useLocation } from "wouter";
import {
  createTheme,
  createStyles,
  ThemeProvider,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Navigator from "./Navigator";
import Header from "./Header";
import GlobalTheme from "../theme/styledComponentsTheme";


import { ErrorBoundary } from "react-error-boundary";
import AutoLogout from "./AutoLogout";

const queryClient = new QueryClient();

interface ErrorFallbackTypes {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorContainer = styled.div`

  width: fit-content;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.grayBackground};
  padding: 30px 40px;
  box-shadow: 1px 2px 15px 0px rgb(0 0 0 / 20%);
  display: flex;
  flex-flow: column nowrap;

  button {
    background-color: ${props => props.theme.colors.green};
    font-family: "futura-pt-bold",Sans-serif;
    font-size: 16px;
    line-height: 1em;
    font-weight: bold;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 80px 80px 80px 80px;
    border-color: #44BBA4;
    fill: #FFFFFF;
    color: #FFFFFF;
    box-shadow: 1px 2px 15px 0px rgb(0 0 0 / 20%);
    padding: 8px 20px 8px 20px;
    transition: 0.2s all ease;
    margin: 0 auto;
  }

  p {
    font-weight: bold;
    margin: 0 auto 0 0;
  }
`;


function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackTypes) {
  console.log("the error", error, Object.keys(error), error.message, error.stack, error.name);
  return (
    <ErrorContainer role="alert">
      <p>Something went wrong:</p>
      <pre>{error.stack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </ErrorContainer>
  );
}


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      Iotcomms {new Date().getFullYear()}.
    </Typography>
  );
}
const MaterialUITheme = {
  ...GlobalTheme,
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
};
let theme = createTheme(MaterialUITheme);

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        // backgroundColor: GlobalTheme.colors.darkBlue,
        backgroundColor: GlobalTheme.colors.midBlue,
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
      disabled: {
        backgroundColor: GlobalTheme.colors.lightGray,
        borderColor: GlobalTheme.colors.lightGray
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        "font-weight": "medium",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = createStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(4, 3),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(1),
    background: "#eaeff1",
  },
});

export interface PaperbaseProps extends WithStyles<typeof styles> {
  children: ReactElement | React.ReactNode
}

interface HeadersSignature {
  [key: string]: string;
}

const headers: HeadersSignature = {
  "/users": "Users Management",
  "/tenants": "Tenants Management",
};

function Paperbase(props: PaperbaseProps) {
  const { classes, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [location] = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <QueryClientProvider client={queryClient} >
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.app}>
            <Header onDrawerToggle={handleDrawerToggle} title={headers[location]} />
            <main className={classes.main}>
              <AutoLogout />
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                }}
              >
                {children}
              </ErrorBoundary>
            </main>
            <footer className={classes.footer}>
              <Copyright />
            </footer>
          </div>
        </div>
      </ThemeProvider>
      {import.meta.env.DEV ?
        <ReactQueryDevtools initialIsOpen={false}/>
        : undefined
      }
    </QueryClientProvider>
  );
}

export default withStyles(styles)(Paperbase);
