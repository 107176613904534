export interface Borders {
  button: {
    borderStyle: string;
    borderWidth: string;
    borderRadius: string;
  }
}

const borders: Borders = {
  button: {
    borderStyle: "solid",
    borderWidth: "3px 3px 3px 3px",
    borderRadius: "80px 80px 80px 80px"
  }
};
export default borders;
