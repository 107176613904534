import { UseDataResponse } from "../customHooks/useData";

export type TenantRolesRelation = {
  domain: string,
  roles: string[]
}

export interface TableUserData {
  [key: string]: string | number | string[] | undefined | {};
  id: string;
  system: string;
  email: string;
  firstName: string;
  lastName: string;
  created: number;
  tenants?: string[] | TenantRolesRelation[],
  roles: string[];
  ttl?: number;
  tenant?: string;
}

interface HandleClickArgs {
  id: string;
  rows: TableUserData[];
  selected: TableUserData[];
}

interface HandleTClickArgs {
  id: string;
  rows: TenantData[];
  selected: TenantData[];
}

export interface UserData {
  auditInfo: {
    created: number,
    termsAgreed: number
  };
  customerInfo: {};
  pk: string;
  profile: {
    email: string
    firstName: string
    lastName: string
    phone: string
  },
  system: string;
  tenants?: string[];
  roles: string[];
  ttl?: number;
}

export interface Data {
  [key: string]: string | number;
  id: string;
  system: string;
  email: string;
  firstName: string;
  lastName: string;
  created: number;
}

export const handleTableCellClick = ({ id, rows, selected }: HandleClickArgs): any[] => {

  const selectedUser = rows.find(s => s.id === id);
  if (!selectedUser) return selected;

  const selectedIndex = selected.findIndex(s => s.id === id);
  let newSelected: any[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, selectedUser);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }
  return newSelected;
};

export const handleTenantTableCellClick = ({ id, rows, selected }: HandleTClickArgs) => {

  if (selected.find(u => u.id === id)) return [];
  const selectedUser = rows.find(s => s.id === id);
  if (!selectedUser) return;
  return [selectedUser];
};


export interface TenantData {
  [key: string]: string | boolean | undefined | number;
  id: string;
  domain: string;
  system: string;
  ttl?: number;
}

export type DeleteRequests = {
  [key: string]: string[];
};

interface ParseTenantRowArgs {
  tenants: UseDataResponse;
  searchString: string;
  selected: TenantData[];
  deleteRequests?: any[];
  returnMarked: boolean;
}
export const parseTenantRows = ({ tenants, searchString, deleteRequests, returnMarked }: ParseTenantRowArgs): Array<TenantData> => {
  if (tenants && tenants.data.length) {
    //merge the data
    let tenantsWithSystem: Array<TenantData> = [];

    let data;
    if (!(deleteRequests && deleteRequests.length)) {
      return [];
    }
    data = tenants.data.map((ur: { tenants: string[], success: boolean, system: string }) => {
      const systRequests = deleteRequests.find(dr => dr.system === ur.system);
      let filteredTenants = ur.tenants.filter(u => {
        const marked = systRequests.data.Items.find((el: DeleteRequest) => el.pk === u);
        return returnMarked ? marked : !marked;
      });
      const remaped = filteredTenants.map(tenant => {
        if (returnMarked) {

          const marked = systRequests.data.Items.find((el: DeleteRequest) => el.pk === tenant);
          return ({ tenant, ttl: marked.timeToLive });
        }
        return ({ tenant });
      });
      return ({ ...ur, tenants: remaped });
    });

    data.forEach((tn: {
      tenants: { tenant: string, ttl?: number }[],
      success: boolean,
      system: string
    }) => {
      if (tn.success) {
        tenantsWithSystem = [
          ...tenantsWithSystem,
          ...tn.tenants.map(({ tenant, ttl }) => {
            return ({
              id: tenant.replace("tenant_", "") + "_" + tn.system,
              domain: tenant.replace("tenant_", ""),
              system: tn.system,
              ttl
            });
          })];
      } else {
        //TODO: raise error flag or log
      }
    });
    let rowProps = tenantsWithSystem;

    if (searchString) {
      rowProps = filterKeyword({ searchString, rowProps });
    }

    // if (selected.length) {
    //   const system: string = selected[0].system;
    //   rowProps = rowProps.filter(row => row.system === system);
    // }


    return rowProps;


  } else return [];
};

interface FilterKeywordArgs {
  searchString: string;
  rowProps: Array<any>;
}

const filterKeyword = ({ searchString, rowProps }: FilterKeywordArgs): Array<any> => {

  const keywords = searchString.split(",").filter(e => !!e).map(k => k.trim());
  return rowProps.filter((row) => {
    const reduce = keywords.reduce((prev, keyword) => {
      const matchKeyword = Object.keys(row).reduce((prev, cell) => {
        const property = String(row[cell]);
        return property.includes(keyword) ? true : prev;
      }, false);
      return !matchKeyword ? false : prev;
    }, true);
    return reduce;
  });
};

interface ParseRowArgs {
  users: UseDataResponse;
  searchString: string;
  selected: TableUserData[];
  tenant?: string;
  tenants?: string[];
  deleteRequests: any[];
  returnMarked: boolean;
}

export interface DeleteRequest {
  pk: string;
  sk: string;
  timetolive: number;
}
export const parseRows = ({ users, searchString, selected, tenant, deleteRequests, returnMarked }: ParseRowArgs): Array<TableUserData> => {

  if (users && users.data && users.data.length && deleteRequests && deleteRequests.length) {
    // if (deleteRequests && deleteRequests.length) {
    let data = users.data.map((ur: { users: UserData[], success: boolean, system: string }) => {
      const systRequests = deleteRequests.find(dr => dr.system === ur.system);
      const filteredUsers = ur.users.filter(u => {
        const marked = systRequests.data.Items.find((el: DeleteRequest) => el.pk === u.pk);
        return returnMarked ? marked : !marked;
      }).map(user => {
        if (returnMarked) {

          const marked = systRequests.data.Items.find((el: DeleteRequest) => el.pk === user.pk);
          return ({ ...user, ttl: marked.timeToLive });
        }
        return ({ ...user });
      });
      return ({ ...ur, users: filteredUsers });
    });
    // }

    //merge the data
    let usersWithSystem: Array<UserData> = [];
    data.forEach((ur: { users: UserData[], success: boolean, system: string }) => {
      if (ur.success) {
        usersWithSystem = [...usersWithSystem, ...ur.users.map(u => ({ ...u, system: ur.system }))];
      } else {
        //TODO: raise error flag or log
      }
    });
    let rowProps = usersWithSystem.map((user: UserData): TableUserData => {
      // user.roles does not exist, since roles is not a property of user
      // but a property of the user/tenant relation
      // therefore it should be left empty here and maybe filled up if tenant is selected

      let row: TableUserData = {
        id: user.pk,
        created: user.auditInfo ? user.auditInfo.created : 0,
        system: user.system,
        firstName: "",
        lastName: "",
        email: "",
        roles: user.roles,
        ttl: user.ttl
      };

      if (user.profile) {
        row = {
          ...row,
          firstName: String(user.profile.firstName),
          lastName: user.profile.lastName,
          email: user.profile.email,
        };
      }
      if (user.tenants) {
        row.tenants = user.tenants;
      }
      return row;
    });

    if (searchString) {


      rowProps = filterKeyword({ searchString, rowProps });
    }

    if (tenant && tenant !== "All") {
      rowProps = rowProps.filter(row => {
        // return row.tenants?.includes(tenant);
        return row.tenants && row.tenants.some((ten: any) => typeof ten === "string" ?
          tenant === ten :
          tenant === ten.domain
        );
      });
    } else if (selected.length) {
      const system: string = selected[0].system;
      rowProps = rowProps.filter(row => row.system === system);
    }


    return rowProps;
  } else return [];
};

export const getLocaleTime = (timestamp: number) => {
  let date = new Date(timestamp).toLocaleDateString();
  const regexMonth = /\/\d\//g;
  const foundMonth = date.match(regexMonth);
  if (foundMonth && foundMonth[0]) {
    date = date.replace(foundMonth[0], `/0${foundMonth[0].replace(/\//g, "")}/`);
  }
  const regexDay = /^\d\//;
  const foundDay = date.match(regexDay);
  if (foundDay && foundDay[0]) {
    date = date.replace(regexDay, `0${foundDay[0]}`);
  }
  return date;
};
