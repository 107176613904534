import React, { ReactElement } from "react";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";


export const PrimaryButton = styled(Button)`
  background-color: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.green};
  ${props => props.theme.typography.button};
  ${props => props.theme.borders.button};
  border-color: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.green};
  fill: #FFFFFF;
  color: #FFFFFF;
  box-shadow: 1px 2px 15px 0px rgb(0 0 0 / 20%);
  padding: 8px 20px 8px 20px;
  transition: 0.2s all ease;
  
  :hover{
    background-color: ${props => props.theme.colors.green};
    transform: scale(1.05);
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.midBlue};
  ${props => props.theme.typography.button};
  ${props => props.theme.borders.button};
  border-color: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.midBlue};
  
  :hover{
    background-color: ${props => props.theme.colors.midBlue};
  }
`;



const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 170,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl2: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  options: Array<{
    value: string;
    label: string;
  }>;
  selected: string;
  setSelected: (value: string) => void;
  label?: string;
  helperText?: string;
  none?: boolean;
};

export function SimpleSelect({ options, selected, setSelected, label, helperText, none }: Props) {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value as string);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        {label &&
          <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        }
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={selected}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          {none &&
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          }
          {options.map(opt => <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>)}
        </Select>
        {helperText &&
          <FormHelperText>{helperText}</FormHelperText>
        }
      </FormControl>
    </div>
  );
};

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #80808036;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface DialogCompoentnProps {
  buttonLabel: string;
  children: ReactElement<any>;
  disableButton: boolean;
  heading: string;
  loading?: boolean;
  width?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined
}

export function SimpleDialog({ heading, buttonLabel, disableButton, children, loading, width }: DialogCompoentnProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, { setOpen });
    }
    return child;
  });

  return (
    <>
      <PrimaryButton variant="contained" color="primary" onClick={handleClickOpen} className={classes.addUser} disabled={disableButton}>
        {buttonLabel}
      </PrimaryButton>
      <Dialog
        fullWidth={true}
        maxWidth={width ? width : "md"}
        open={open}
        onClose={handleClose}
        className="dialog-component"
        aria-labelledby="max-width-dialog-title"
      >
        {loading ?
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
          :
          <>
          </>
        }
        <DialogTitle id="max-width-dialog-title">{heading}</DialogTitle>
        <DialogContent>
          {loading ?
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
            :
            <>
              {childrenWithProps}
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
