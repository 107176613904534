import { useEffect, useContext, useReducer, Dispatch } from "react";
import styled from "styled-components";
import { MainContext } from "../contexts/MainContext";
import { useCookies } from "react-cookie";
import useLocalStorage from "react-use-localstorage";
import { makeRequest } from "../customHooks/useData";
import useLogout from "../customHooks/useLogout";

import CircularProgress from "@material-ui/core/CircularProgress";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Credentials } from "../types";

import { PrimaryButton } from "./Inputs";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import { StartDeviceAuthorizationResponse } from "@aws-sdk/client-sso-oidc";

import { AWSError } from "aws-sdk";


const AuthCard = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 500px;
  background-color: ${props => props.theme.colors.grayBackground};
  padding: 30px 40px;
  box-shadow: 1px 2px 15px 0px rgb(0 0 0 / 20%);

`;

const RestyledLock = styled(LockOpenIcon)`
  font-size: 8rem;
  margin: 0 auto;
  fill: ${props => props.theme.colors.main};
  margin-bottom: 40px;
`;

const StyledRefresh = styled(RefreshIcon)`
  cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.grayBackground};
`;
interface LocalState {
  loading: boolean;
  loginUrl?: string;
  loginResponse?: object;
  failed: boolean;
  reason: string;
}

const initialState: LocalState = {
  loginUrl: undefined,
  loading: false,
  loginResponse: undefined,
  failed: false,
  reason: ""
};

interface Action {
  type: string;
  loginUrl?: string;
  loginResponse?: object;
  reason?: string;
}

export interface Actions {
  [key: string]: () => LocalState;
}



const reducer = (state: LocalState, action: Action): LocalState => {
  const actions: Actions = {
    "START_LOADING": () => {
      return {
        ...state,
        loading: true,
      };
    },
    "AUTHENTICATION_STARTED": () => {
      return {
        ...state,
        loading: false,
        loginUrl: action.loginUrl,
        loginResponse: action.loginResponse
      };
    },
    "AUTHENTICATION_DONE": () => {
      return {
        ...state,
        loading: false,

      };
    },
    "AUTHENTICATION_FAILED": () => {
      const returnObject = {
        ...state,
        loading: false,
        failed: true,
      };
      if (action.reason) {
        returnObject.reason = action.reason;
      }
      return returnObject;
    },
    "RESET": () => {
      return initialState;
    },
    "default": () => ({ ...state }),
  };

  return actions[action.type] ? actions[action.type]() : actions["default"]();
};

interface GetJWTArgs {
  credentials: Credentials[]
}
export const GetJWT = async ({ credentials }: GetJWTArgs) => {
  try {
    const data = await makeRequest({
      path: "/getjwttoken/sso",
      resource: "tenantApi",
      getJwtRequest: true
      // urls
    });
    console.log("the token request response", data);
    let credsWithJWT: Credentials[] = credentials;
    data?.filter(e => !(e.success === false)).forEach(resp => {
      resp.roles.forEach((role: string) => {
        // breaks when role doesn't exist
        const index = credsWithJWT.findIndex((cred) => {
          return cred.roleName === role && resp.accountId === cred.accountId;
        });
        console.log("adding jwt to the credentials", role, resp.accountId);
        console.log("the current credentials", credentials);
        if (index > -1) {
          credsWithJWT[index].jwt.push({ token: resp.data, system: resp.system });
        }
      });
    });
    return credsWithJWT;

  } catch (error) {
    console.error("Failed to get JWT tokens for the roles", error);
    return credentials;
  }

};

export const Logout = () => {

  const { logout } = useLogout({from: "Logout button"});

  return <PrimaryButton variant="contained" color="primary" onClick={logout}>
    Logout
  </PrimaryButton>;
};

interface FetchCredentialsArgs {
  localDispatch: (action: Action) => void
  setCredentials: (arg1: string) => void;
  setCookie: (arg1: string, arg2: string, arg3: any) => void;
  globalDispatch: Dispatch<Action>;
}

interface Response extends StartDeviceAuthorizationResponse {
  success: boolean;
  clientId: string;
  clientSecret: string;
  expirationTime: number | undefined;
}

type ResolveCredentialsResponse = {
  success: boolean;
  reason?: string;
  error?: AWSError;
  credentials?: Credentials[]
}

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

const resolveCredentials = async (loginResponse: Response): Promise<ResolveCredentialsResponse> => {

  console.log("this is running");

  try {

    const getCredentials = async () => {

      const response = await fetch("https://nugupvpb6e.execute-api.eu-west-1.amazonaws.com/prod",
        {
          method: "POST",
          body: JSON.stringify(loginResponse)
        });
      return await response.json();
    };

    const { success, credentials, reason, error } = await getCredentials();

    if (success) {
      return { success: true, credentials };
    }

    if (reason && reason === "Authorization pending, please retry in some seconds, or after authorization is complete") {
      await sleep(3000);
      return await resolveCredentials(loginResponse);
    }

    return { success: false, reason, error };

  } catch (error) {
    const typedError = (error as AWSError);

    return { success: false, reason: "Error thrown while recursively querying for credentials", error: typedError };

  }

};

export const fetchCredentials = async ({ localDispatch, setCredentials, setCookie, globalDispatch }: FetchCredentialsArgs): Promise<void> => {
  let win;
  const data = await fetch("https://nugupvpb6e.execute-api.eu-west-1.amazonaws.com/prod");
  const loginResponse = await data.json();

  if (loginResponse.verificationUriComplete) {
    localDispatch({ type: "AUTHENTICATION_STARTED", loginUrl: loginResponse.verificationUriComplete, loginResponse: loginResponse });
    win = window.open(loginResponse.verificationUriComplete, "popup", "width=600,height=600");
  } else {
    return;
  }
  try {




    localDispatch({ type: "START_LOADING" });

    const { credentials, reason } = await resolveCredentials(loginResponse);
    // const response = await fetch("https://nugupvpb6e.execute-api.eu-west-1.amazonaws.com/prod",
    //   {
    //     method: "POST",
    //     body: JSON.stringify(loginResponse)
    //   });
    // const data = await response.json();

    console.log("Am here and credentials are:", credentials);

    if (credentials && credentials.length) {
      const expiration = credentials
        .map(it => it.expiration)
        .reduce((prev, cur) => cur < prev ? cur : prev) - 1000;

      console.log("setCredentials called!");
      setCredentials(JSON.stringify(credentials));

      const creds = await GetJWT({
        credentials: credentials.map((c: Credentials) => {
          c.jwt = [];
          return c;
        })
      });
      console.log("the getJWt response", creds);
      setCookie("authenticated", "authenticated", { expires: new Date(expiration) });
      localStorage.setItem("credentials", JSON.stringify(creds));
      // const returnValue = setCredentials(JSON.stringify(creds));
      console.log("the credentials where stored in the localStorage");
      globalDispatch({ type: "AUTHENTICATED" });
      localDispatch({ type: "AUTHENTICATION_DONE" });

    } else {
      localDispatch({
        type: "AUTHENTICATION_FAILED",
        reason: reason ? reason : "No credentials were found for current user"
      });
    }


  } catch (error) {
    console.error("Failed to login", error);
    localDispatch({ type: "AUTHENTICATION_FAILED", reason: JSON.stringify(error) });
  }
  if (win) win.close();
  return;
};

const Login = () => {

  const [localState, localDispatch] = useReducer(reducer, initialState);
  const { loading, failed, reason } = localState;

  const { globalDispatch } = useContext(MainContext);
  const [cookies, setCookie] = useCookies<string>(["authenticated"]);
  const [credentials, setCredentials] = useLocalStorage("credentials");



  useEffect(() => {
    if (!cookies.authenticated) {
      console.log("Setting credentials to empty string because:", cookies);
      setCredentials("");
    }
  }, [cookies, setCredentials]);



  return <>
    {cookies.authenticated && credentials ? <></> :
      <Overlay>
        {failed ? <>
          {reason}
          <StyledRefresh onClick={() => localDispatch({ type: "RESET" })}></StyledRefresh>
        </>
          :
          <AuthCard>

            {loading ?
              <CircularProgress /> :
              <>
                <RestyledLock />
                <PrimaryButton onClick={() => fetchCredentials({ localDispatch, globalDispatch, setCookie, setCredentials })}>
                  LOGIN
                </PrimaryButton>
              </>
            }

          </AuthCard>
        }
      </Overlay>
    }
  </>;
};

export default Login;
