import { useEffect, useState, useRef } from "react";

/**
 * Return whether or not the current page is in the foreground
 */
export default function useVisible(): [boolean, boolean] {
  const [visible, setVisible] = useState(!document.hidden);
  const previousState = useRef(visible);

  useEffect(() => {
    const handler = () => {
      previousState.current = visible;
      setVisible(!document.hidden);
    };
    
    document.addEventListener("visibilitychange", handler, false);
    return () => document.removeEventListener("visibilitychange", handler);
  }, [visible]);

  return [visible, previousState.current];
};
