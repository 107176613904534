import { signedFetch, billingCredentials } from "../utils";
import { PrimaryButton } from "../Inputs";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import styled from "../../util/styled";
import { stages, defaultStage, BillingStageSelector } from "./billingStages";
import useLocalStorage from "react-use-localstorage";
import { Spinner } from "../Spinner";

const Container = styled.div`
  padding: 2em;
  min-width: 35em;

  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const Title = styled.h1`
  margin: 0;
  margin-bottom: 1em;;
  font-size: 1.2em;
`;

const InvoicingReport = (props: {  path: string }) => {

  const [stage, setStage] = useState<string>(defaultStage);
  const [credentials] = useLocalStorage("credentials");
  const [waiting, setWaiting] = useState<boolean>(false);

  const defaultDate = new Date();
  defaultDate.setMonth(defaultDate.getMonth() - 1);

  const [date, setDate] = useState<Date>(defaultDate);
  
  const handleDateUpdate = (newDate: Date | null) => {
    if (newDate != null) {
      setDate(newDate);
    }
  };

  const handleStageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStage(event.target.value as string);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Title>Generate rated invoicing report</Title>
        <div>
          <BillingStageSelector onChange={handleStageChange} state={stage}/>
        </div>
        <div>
          <DatePicker
            renderInput={(props) => <TextField {...props} />}
            label="Select month"
            value={date}
            onChange={handleDateUpdate}
            views={["year", "month"]}
          />
        </div>
        <div style={{display: `${waiting ? "inherit" : "none"}`, height: "80px", marginTop: "25px", marginLeft:"80px"}}>
          <Spinner
            size="4em"
            $color={"primary"}
          />
        </div>
        <div>
          <PrimaryButton disabled={waiting} onClick={() => {
            const url = `${stages[stage].url}rating?date=${date}`;
            setWaiting(true);
            signedFetch({credentials: billingCredentials(credentials)[stages[stage].role], region:"eu-west-1", service:"execute-api"})
            (url, {})
              .then(response => {
                if(response.ok) {
                  response.text().then(url => {
                    const iframe = document.getElementById("result-content") as HTMLIFrameElement;
                    iframe.src = url;
                    setWaiting(false);
                  });
                } else {
                  console.error(`Failed to fetch report. Result: ${JSON.stringify(response.body)}`);
                }
                setWaiting(false);
              })
              .catch(err => {
                console.error(err);
                setWaiting(false);
              });
          }}>Generate and download rated invoicing report</PrimaryButton>
        </div>
        <div style={{display: "none"}}>
          <iframe id="result-content" title="result-content"/>
        </div>
      </Container>
    </LocalizationProvider>
  );
};

export default InvoicingReport;
